import DailyRevenueReport from "./DailyRevenue";
import WeeklyRevenueReport from "./WeeklyRevenue";
import MonthlyRevenueReport from "./MonthlyRevenue";
import YearlyRevenueReport from "./YearlyRevenue";

const Revenue = () => {
	return (
		<div>
			<div className="container-fluid">
				<div className="content p-3">
					<div className="row shadow-lg pt-2 mb-2 bg-blue rounded">
						<DailyRevenueReport/>
					</div>
					<div className="row shadow-lg pt-2 mb-2 bg-yellow rounded">
						<WeeklyRevenueReport/>
					</div>
					<div className="row shadow-lg pt-2 mb-2 bg-green rounded">
						<MonthlyRevenueReport/>
					</div>
					<div className="row shadow-lg pt-2 mb-2 bg-red rounded">
						<YearlyRevenueReport/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Revenue; 