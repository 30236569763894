import { vBoolGolf } from "../../Global"
import Weather from "./Weather";
import ViewPoint from "./ViewPoint";
import FoodVsBeverage from "./FoodVsBeverage";
import BookingVsCheckin from "./BookingVsCheckin";
import MemberVsGuest from "./MemberVsGuest";
import './StyleMainPage.css';
import NewVsFrequent from "./NewVsFrequent";

const MainPage = () => {
	return (
		<>
			{
				vBoolGolf == true
				?
				<div className="container-fluid bg-white rounded">
					<div className="content p-3">
						<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
							<div className="col-12">
								<div className="row">
									<Weather/>
									<ViewPoint/>
									<BookingVsCheckin/>
									<FoodVsBeverage/>
									<MemberVsGuest/>
									<NewVsFrequent/>
								</div>
							</div>
						</div>
					</div>
				</div>
				:
				null
			}
		</>
	)
}

export default MainPage