import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { vAPIURL, vGlobalDateNow } from "../../Global";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import 'chart.js/auto';
import "react-datepicker/dist/react-datepicker.css";
import format from 'date-fns/format';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(...registerables )

const FoodVsBeverage = () => {
	const [ Loading, setLoading ] = useState( false )
	const [ FnBData, setFnBData ] = useState( [] )
	const date = new Date( vGlobalDateNow )

	const GetFnBData = async() => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Main/FnB/Dev"
			const vData = {
				Action: {
					Value: "Search"
				},
				Input: {
					Date: {
						Value: format( date, 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						for( let i = 1 ; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setFnBData( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false );
	}

	useEffect(() => {
		GetFnBData()
	},[])

	let ChartData = {
		labels: FnBData.map(Value => Value.Date),
		datasets: [
			{
				label: 'BEVERAGE'
				, data: FnBData.map( Value => Value.Beverage )
				, backgroundColor: 'rgb( 206,212,218 )'
				, borderColor: 'rgb( 206,212,218 )'
				, borderWidth: 2
			},
			{
				label: 'FOOD'
				, data: FnBData.map( Value => Value.Food )
				, backgroundColor: 'rgb( 0,123,255 )'
				, borderColor: 'rgb( 0,123,255 )'
				, borderWidth: 2
			},
		],
	};

	let ChartOption = {
		maintainAspectRatio: false
		, responsive : true
		, scales: {
			x: {
				beginAtZero: true
			},
			y: {
				beginAtZero: true
			},
		}
		, plugins: {
			legend: {
				display: true
				, position: 'top'
				, align: 'center'
				, labels: {
					boxHeight: 10
					, boxWidth: 10
					, textAlign: 'left'
				}
			}
			, tooltip: {
				callbacks: {
					title: function(tooltipItem, data) {
						return '';
					}
				}
			}
			, datalabels: {
				anchor: 'center'
				, align: 'center'
				, display: true
				, font: {
					size: 10
				}
			}
		}
		, onHover: (e, ChartElement) => {
			e.native.target.style.cursor = ChartElement[0] ? 'pointer' : 'default'
		},
	}

	return (
		<div className="col-12 col-md-6">
			<div className="card bg-gradient-light">
				<div className="card-header">
					<h3 className="card-title text-success font-weight-bold">
						BEVERAGE VS FOOD IN PORTIONS
					</h3>
					<div className="card-tools">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
							</li>
						</ul>
					</div>
				</div>
				<div className="CardBody-Chart">
					<div className="tab-content p-0">
						{	
							Loading ?
								<div className="d-flex justify-content-center">
									<i className="fas fa-3x fa-sync-alt fa-spin"/>
								</div>
							:
								<div className="Chart-Container">
									<Bar
										data={ChartData}
										plugins={[ChartDataLabels]}
										options={ChartOption}
										height={null}
										width={null}
									/>
								</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default FoodVsBeverage