import React, { useState, Suspense, lazy } from 'react';
import { vGlobalDateNow } from '../../Global';
import DatePicker from 'react-datepicker';
import Loading from '../Revenue/LazyLoading';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, endOfYear, startOfYear } from 'date-fns';
import './StyleDatePicker.css';
import toastr from "toastr";

const CategoryBox = lazy( () => import( './CategoryBox.js') );

const SearchBar = () => {

	const [ Format, SetFormat ] = useState( 0 );
	const [ Periodic , SetPeriodic ] = useState( 0 );
	const [ OpenCategoryBox, SetOpenCategoryBox ] = useState( false );
	const [ OpenDetailChart, SetOpenDetailChart ] = useState( false );
	const [ SearchData, SetSearchData ] = useState( {} );

	const NewDate = new Date( vGlobalDateNow );
	NewDate.setMonth( NewDate.getMonth() - 2 );

	const [ StartDate, SetStartDate ] = useState( new Date( NewDate ) );
	const [ EndDate, SetEndDate ] = useState( new Date( vGlobalDateNow ) );

	let NumericFormat = "In Billions";
	let PeriodicFormat = "All";

	let SearchDataTemp = {
		StartDate : StartDate
		, EndDate : EndDate
		, NumericFormat : Format
		, PeriodicFormat : Periodic
	}

	switch( Format ){
		case 0:
			NumericFormat = "Full Amount"
			break;
		case 1:
			NumericFormat = "In Millions"
			break;
		case 0:
			NumericFormat = "Full Amount"
			break;
		default:
			NumericFormat = "In Billions"
			break;
	}

	switch( Periodic ){
		case 0:
			PeriodicFormat = "All"
			break;
		case 1:
			PeriodicFormat = "Weekly"
			break;
		case 2:
			PeriodicFormat = "Monthly"
			break;
		default:
			PeriodicFormat = "Yearly"
			break;
	}

	function HandleSearchClick(){
		if( StartDate > EndDate ){
			toastr.error( "Start Date is bigger than End Date" );
			SetOpenCategoryBox( false )
		}
		else{
			if( OpenCategoryBox != true ){
				SetOpenCategoryBox( true )
			}
		}
		SetSearchData( SearchDataTemp )
	}

	const ToggleCategoryBox = () => {
		SetOpenCategoryBox( false );
	};

	const ToggleDetailChart = () => {
		SetOpenDetailChart( false );
	};

	const renderMonthContent = ( month, shortMonth, longMonth ) => {
		return <span>{shortMonth}</span>;
	}

	const renderYearContent = ( year ) => {
		return <span>{year}</span>;
	};

	return (
		<>
			<section className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-2">
							<div className="form-group">
								<label>
									Start Posting Date
								</label>
								{
									Periodic === 0 ?
										<DatePicker
											dateFormat="dd-MMM-yyyy"
											selected={StartDate}
											onChange={e => SetStartDate(e)}
											className="form-control"
											value={StartDate}
										/>
									:
									Periodic === 1 ?
										<DatePicker
											dateFormat="dd-MMM-yyyy"
											selected={StartDate}
											onChange={e => SetStartDate(startOfWeek(e, { weekStartsOn: 0 }))}
											className="form-control"
											value={StartDate}
										/>
									:
									Periodic === 2 ?
										<DatePicker
											dateFormat="MMM-yyyy"
											renderMonthContent={renderMonthContent}
											showMonthYearPicker
											selected={StartDate}
											onChange={e => SetStartDate(startOfMonth(e))}
											className="form-control"
											value={StartDate}
										/>
									:
									Periodic === 3 ?
										<DatePicker
											dateFormat="yyyy"
											renderYearContent={renderYearContent}
											showYearPicker
											selected={StartDate}
											onChange={e => SetStartDate(startOfYear(e))}
											className="form-control"
											value={StartDate}
										/>
									:
									null
								}
							</div>
						</div>
						<div className="col-xl-2">
							<div className="form-group">
								<label>
									End Posting Date
								</label>
								{
									Periodic === 0 ?
										<DatePicker
											dateFormat="dd-MMM-yyyy"
											selected={EndDate}
											onChange={e => SetEndDate(e)}
											className="form-control"
											value={EndDate}
										/>
									:
									Periodic === 1 ?
										<DatePicker
											dateFormat="dd-MMM-yyyy"
											selected={EndDate}
											onChange={e => SetEndDate(endOfWeek(e, { weekStartsOn: 0 }))}
											className="form-control"
											value={EndDate}
										/>
									:
									Periodic === 2 ?
										<DatePicker
											dateFormat="MMM-yyyy"
											renderMonthContent={renderMonthContent}
											showMonthYearPicker
											selected={EndDate}
											onChange={e => SetEndDate(endOfMonth(e))}
											className="form-control"
											value={EndDate}
										/>
									:
									Periodic === 3 ?
										<DatePicker
											dateFormat="yyyy"
											renderYearContent={renderYearContent}
											showYearPicker
											selected={EndDate}
											onChange={e => SetEndDate(endOfYear(e))}
											className="form-control"
											value={EndDate}
										/>
									:
									null
								}
							</div>
						</div>
						<div className="col-xl-2">
							<button
								onClick={() => {
									HandleSearchClick()
								}}
								type="submit"
								className="btn btn-primary float-left"
								style={{ width:'75px', marginTop:"31px", paddingLeft:0, paddingRight:0 }}
							>
								<i className="fas fa-search"/>
								&nbsp;
								Search
							</button>
						</div>
						<div className="col-xl-4">
							<div className="dropdown show">
								<a className="btn btn-primary float-right dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width:'150px', marginTop:"31px" }}>
									{PeriodicFormat}
								</a>
								<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
									<button 
										className="dropdown-item" 
										type="button"
										onClick={() => {
											SetPeriodic(0)
											SetStartDate(null)
											SetEndDate(null)
										}}
									>
										All
									</button>
									<button 
										className="dropdown-item" 
										type="button"
										onClick={() => {
											SetPeriodic(1)
											SetStartDate(null)
											SetEndDate(null)
										}}
									>
										Weekly
									</button>
									<button
										className="dropdown-item" 
										type="button"
										onClick={() => {
											SetPeriodic(2)
											SetStartDate(null)
											SetEndDate(null)
										}}
									>
										Monthly
									</button>
									<button 
										className="dropdown-item" 
										type="button"
										onClick={() => {
											SetPeriodic(3)
											SetStartDate(null)
											SetEndDate(null)
										}}
									>
										Yearly
									</button>
								</div>
							</div>
						</div>
						<div className="col-xl-2">
							<div className="dropdown show">
								<a className="btn btn-primary float-right dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width:'150px', marginTop:"31px" }}>
									{NumericFormat}
								</a>
								<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
									<button 
										className="dropdown-item" 
										type="button"
										onClick={() => {
											SetFormat(0)
										}}
									>
										Full Amount
									</button>
									<button
										className="dropdown-item" 
										type="button"
										onClick={() => {
											SetFormat(1)
										}}
									>
										In Millions
									</button>
									<button 
										className="dropdown-item" 
										type="button"
										onClick={() => {
											SetFormat(2)
										}}
									>
										In Billions
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{OpenCategoryBox ?
				<Suspense fallback={<Loading />}>
					<CategoryBox
						SearchBarData={SearchData}
						ToggleCategoryBox={ToggleCategoryBox}
						ToggleDetailChart={ToggleDetailChart}
					/>
				</Suspense>
				: 
				null
			}
		</>
	)
}

export default SearchBar