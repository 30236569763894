import React, { useEffect,useState }from "react";

import Golf_BG from './../Assets/Golf_BG.jpg';
import Travel_BG from './../Assets/Travel_BG.png';
import Askara_BG from './../Assets/Askara_BG.png';
import { vBoolGolf, vBoolTravel } from './../Global';

const NotFoundPath = () => {
	const [timeLeft, setTimeLeft] = useState(5000)
	let vAuthentication = "";

	if( localStorage.getItem( "Authentication" ) !== null ){
		vAuthentication = localStorage.getItem( "Authentication" );
	}

	useEffect(() => {
		if( timeLeft <= 0 ){
			if( vAuthentication === "" ){
				window.location.replace( "/Sign/In" );
			}
			else{
				window.location.replace( "/" );
			}
		}

		const countdownTimer = setInterval(() => {
			setTimeLeft((prevTime) => prevTime - 1000); // Decrease time by 1 second (1000ms)
		}, 1000);
	  
		return () => clearInterval(countdownTimer)		
	}, [vAuthentication, timeLeft]);

	let vBG = Askara_BG;

	if( vBoolGolf && vBoolTravel ){
	}
	else if( vBoolGolf ){
		vBG = Golf_BG;
	}
	else if( vBoolTravel ){
		vBG = Travel_BG;
	}

	return (
		<div
			style={{ 
				backgroundImage: `url(${vBG})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				height: '100vh',
				padding: '10px'
			}}
		>
			<div align="center">
				<div className="login-box">
					<div className="card card-outline card-primary" style={{ marginTop: '35%' }}>
						<div className="card-header text-center">
							<h5>
								Link not found
							</h5>
						</div>
						<div className="card-body">
							<div className="card-body text-center">
								<h5>
									Redirecting in...
								</h5>
							</div>
							<div className="card-body text-center">
								<h5>
									{Math.ceil(timeLeft / 1000)}
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotFoundPath