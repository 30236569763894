import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";

const SalesManagement = () => {

	return (
		<div>
			<div className="container-fluid">
				<div className="row mb-2">
					<div className="col-sm-6">
					</div>
					<div className="col-sm-6">
						<ol className="breadcrumb float-sm-right">
							<li className="breadcrumb-item">
								<Link to={`/`}>Home</Link>
							</li>
							<li className="breadcrumb-item active">Sales Management</li>
						</ol>
					</div>
				</div>
				<section className="content border border-primary p-2 mt-2">
					<SearchBar/>
				</section>
			</div>
		</div>
	);
};

export default SalesManagement