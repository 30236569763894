import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import axios from "axios";
import { vAPIURL, vGlobalDateNow, vBoolDev } from "../../Global";
import toastr from "toastr";
import format from 'date-fns/format';
import { GenerateCircle, GetTop3Index, GetBottom3Index, GetExcludedIndex } from "./SupportFunction/Function.js"

const TableContributionER = () => {
	const [ ERDataRaw, SetERData ] = useState( [] );
	const [ loading, setLoading ] = useState( false );
	const [ disable, setDisable ] = useState( 0 );
	
	let options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	const Tanggal = new Date( vGlobalDateNow ).toLocaleDateString("en-GB", options);

	let header = "TODAY";

	if( disable === 0 ){
		header = "TODAY";
	}
	else if( disable === 1 ){
		header = "WEEK TO DATE";
	}
	else if( disable === 2 ){
		header = "MONTH TO DATE";
	}
	else{
		header = "YEAR TO DATE";
	}

	const GetERData = async () => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];
		
		try{
			const vURL = vBoolDev == true ? vAPIURL + "/Golf/Reservation/ER" : vAPIURL + "/Golf/Reservation/NewER";
			const DataToday = {
				Action: {
					Value: "Search",
				},
				Input: {
					Time: {
						Type: "Today"
						, Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' )
					},
				},
			};
			const DataWeekly = {
				Action: {
					Value: "Search",
				},
				Input: {
					Time: {
						Type: "Weekly"
						, Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' )
					},
				},
			};
			const DataMonthly = {
				Action: {
					Value: "Search",
				},
				Input: {
					Time: {
						Type: "Monthly"
						, Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const DataYearly = {
				Action: {
					Value: "Search",
				},
				Input: {
					Time: {
						Type: "Yearly"
						, Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};

			let data;
			if( disable === 0 ){
				data = DataToday;
			}
			else if( disable === 1 ){
				data = DataWeekly;
			}
			else if( disable === 2 ){
				data = DataMonthly;
			}
			else{
				data = DataYearly;
			}

			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			const vResponse = await axios.post( vURL, data, vConfig );

			vStatus = vResponse.data.Status;

			for( var i = 0; i < vResponse.data.Message.length; i++ ){
				vMessage.push( vResponse.data.Message[i] );
			}
			if( vStatus === 1 ){
				SetERData(vResponse.data.Output.Result);
			}
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		
		setLoading( false );
	};

	useEffect(() => {
		GetERData();
	}, [disable]);

	let SumBooking = 0;
	let SumActual = 0;
	ERDataRaw.forEach((Data) => {
		SumBooking += parseInt(Data.QtyBook);
		SumActual += parseInt(Data.QtyAct);
	});

	const normalizeBooking = (value, sumBooking) => (value * 100) / sumBooking;
	const NormaliseBooking = (value) => ((value - 0) * 100) / (SumBooking - 0);
	const NormaliseActual = (value) => ((value - 0) * 100) / (SumActual - 0);
	
	function FormatData(
		Username,
		QtyBook,
		QtyAct,
		BookingContribution,
		Reliability,
		ActualContribution
	) {
		const NormaliseReliability = (value) => parseFloat((value - 0) * 100) / (QtyBook - 0);
		BookingContribution = NormaliseBooking(QtyBook);
		BookingContribution = (Math.round(BookingContribution * 100) / 100).toFixed(1);
		ActualContribution = NormaliseActual(QtyAct);
		ActualContribution = (Math.round(ActualContribution * 100) / 100).toFixed(1);
		Reliability = NormaliseReliability(QtyAct);
		Reliability = (Math.round(Reliability * 100) / 100).toFixed(1);
	
		return {
			Username,
			QtyBook,
			QtyAct,
			BookingContribution: isNaN(BookingContribution) ? 0 : BookingContribution,
			Reliability: isNaN(Reliability) ? 0 : Reliability,
			ActualContribution: isNaN(ActualContribution) ? 0 : ActualContribution,
		};
	}

	let AvgReliability = normalizeBooking(SumActual, SumBooking).toFixed(1);
	
	const ERData = ERDataRaw.map((Data) => {
		const { Username, QtyBook, QtyAct, BookingContribution, Reliability } =
			Data;
		return FormatData(
			Username,
			QtyBook,
			QtyAct,
			BookingContribution,
			Reliability
		);
	});

	const ExcludedIndex = GetExcludedIndex( ERDataRaw )

	const ActualContribution = ERDataRaw.map(Value => parseInt(Value.QtyAct))
	const BookingContribution = ERDataRaw.map(Value => parseInt(Value.QtyBook))
	const Reliability = ERData.map(Value => parseFloat(Value.Reliability))

	const Bottom3ActualContribution = GetBottom3Index(ActualContribution, ExcludedIndex);
	const Bottom3BookingContribution= GetBottom3Index(BookingContribution, ExcludedIndex);
	const Bottom3Reliability = GetBottom3Index(Reliability, ExcludedIndex);

	const Top3ActualContribution = GetTop3Index(ActualContribution, ExcludedIndex);
	const Top3BookingContribution= GetTop3Index(BookingContribution, ExcludedIndex);
	const Top3Reliability = GetTop3Index(Reliability, ExcludedIndex);

	const [ vTimer, SetTimer ] = useState( 0 );

	useEffect(() => {
		const timer = setTimeout(() => {
			GetERData()
				SetTimer( 600000 );
			}, vTimer);
		return () => clearTimeout(timer);
	})

	function ButtonLogic(){
		return (
			<Box>
				<Button
					sx={{ mr: 2 }}
					disabled={disable === 0}
					onClick={() => setDisable(0)}
					variant="contained"
				>
					Today
				</Button>
				<Button
					sx={{ mr: 2 }}
					disabled={disable === 1}
					onClick={() => setDisable(1)}
					variant="contained"
				>
					WTD
				</Button>
				<Button
					sx={{ mr: 2 }}
					disabled={disable === 2}
					onClick={() => setDisable(2)}
					variant="contained"
				>
					MTD
				</Button>
				<Button
					disabled={disable === 3}
					onClick={() => setDisable(3)}
					variant="contained"
				>
					YTD
				</Button>
			</Box>
		);
	}

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<ul className="nav nav-pills ml-auto">
					<h3 className="card-title text-success font-weight-bold">
						RESERVATION STAFF PRODUCTIVITY RATE
					</h3>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<h5 className="card-title text-info font-weight-bold">
						{header}
					</h5>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<h6 className="nav-item text-info">
						{Tanggal}
					</h6>
				</ul>
			</div>
			{
				loading
				? 
				<div className="d-flex justify-content-center">
					<i className="fas fa-3x fa-sync-alt fa-spin"></i>
				</div>
				:
				<>
					<div align="center">
						<ButtonLogic />
					</div>
					<TableContainer component={Paper}>
						<Table size="small" aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} rowSpan={2}>
										STAFF
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="center" colSpan={2}>
										RESERVATION
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="center" colSpan={2}>
										ACTUAL
									</TableCell>
									<TableCell sx={{ border: 1, width: 150, fontWeight: 'bold' }} rowSpan={2} align="center">
										RESERVATION RELIABILITY
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{ border: 1, width: 150, fontWeight: 'bold' }} align="center">
										PAX
									</TableCell>
									<TableCell sx={{ border: 1, width: 150, fontWeight: 'bold' }} align="center">
										%
									</TableCell>
									<TableCell sx={{ border: 1, width: 150, fontWeight: 'bold' }} align="center">
										PAX
									</TableCell>
									<TableCell sx={{ border: 1, width: 150, fontWeight: 'bold' }} align="center">
										%
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									ERData.map((row, Index) => (
									<TableRow key={row.Username} sx={{ border: 1 }}>
										<TableCell sx={{ border: 1 }} component="th" scope="row">
											{row.Username}
										</TableCell>
										<TableCell sx={{ border: 1 }} align="right">
											{row.QtyBook}
										</TableCell>
										<TableCell sx={{ border: 1 }} align="right">
											{ GenerateCircle( row.BookingContribution, Top3BookingContribution, Bottom3BookingContribution, Index ) }
										</TableCell>
										<TableCell sx={{ border: 1 }} align="right">
											{row.QtyAct}
										</TableCell>
										<TableCell sx={{ border: 1 }} align="right">
											{ GenerateCircle( row.ActualContribution, Top3ActualContribution, Bottom3ActualContribution, Index ) }
										</TableCell>
										<TableCell sx={{ border: 1 }} align="right">
											{ GenerateCircle( row.Reliability, Top3Reliability, Bottom3Reliability, Index ) }
										</TableCell>
									</TableRow>
								))}
								<TableRow>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }}>
										TOTAL
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="right">
										{SumBooking}
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="right">
										100 %
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="right">
										{SumActual}
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="right">
										100 %
									</TableCell>
									<TableCell sx={{ border: 1, fontWeight: 'bold' }} align="right">
										{AvgReliability}%
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</>
			}
		</div>
	);
};
export default TableContributionER