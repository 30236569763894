import React from 'react'
import BalanceSheetPieChart from "./BalanceSheetPieChart";

const BalanceSheet = () => {

	return (
		<div>
			<div className="container-fluid">
				<div className="content p-3">
					<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
						<div className="col-12">
							<BalanceSheetPieChart/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BalanceSheet