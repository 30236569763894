import React, { useEffect, useState } from "react"
import axios from "axios";
import toastr from "toastr";
import { vAPIURL } from "../../Global";

const UserConfig = () => {
	const [ UsernameArrayList, setUsernameArrayList ] = useState( [] )
	const [ UsernameArrayListCode, setUsernameArrayListCode ] = useState( [] )
	const [ Uid, setUid ] = useState( "" )
	const [ Fullname, setFullname ] = useState( "" )
	const [ Email, setEmail ] = useState( "" )
	const [ RuleArrayList, setRuleArrayList ] = useState( [] )
	const [ RuleArrayListCode, setRuleArrayListCode ] = useState( [] )
	const [ Rule, setRule ] = useState( "" )
	const [ NoTelp, setNoTelp ] = useState( "" )
	const [ Description, setDescription ] = useState( "" )
	const [ StatusUser, setStatusUser ] = useState( "" )
	const [ LoadingPage, setLoadingPage ] = useState( true )

	const FGenUsername = async () => {
		setLoadingPage( true )
		let vStatus = 1
		let vMessage = []

		try{
			const vURL = vAPIURL + "/Golf/Config/GenUsername";
			const vData = {};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setUsernameArrayList( vResponse.data.Input.Username.List )
							setUsernameArrayListCode( vResponse.data.Input.Username.ListCode )
							setRuleArrayList( vResponse.data.Input.Rule.List )
							setRuleArrayListCode( vResponse.data.Input.Rule.ListCode )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "FGenUsername Error 1" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "FGenUsername Error 2" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoadingPage( false )
	}
	
	const FUserInfo = async ( UidTemp ) => {
		let vStatus = 1
		let vMessage = []

		try{
			const vURL = vAPIURL + "/Golf/Config/" + UidTemp;
			const vData = {};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setRuleArrayList( vResponse.data.Input.Rule.List )
							setRuleArrayListCode( vResponse.data.Input.Rule.ListCode )
							setStatusUser( vResponse.data.Input.Status.Value )
							setFullname( vResponse.data.Input.Fullname.Value )
							setEmail( vResponse.data.Input.Email.Value )
							setNoTelp( vResponse.data.Input.NoTelp.Value )
							setDescription( vResponse.data.Input.Description.Value )
							setRule( vResponse.data.Input.Rule.Value )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "FUserInfo Error 1" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "FUserInfo Error 2" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
	}
	
	const FEditUser = async ( 
		UidTemp
		, FullnameValue
		, EmailValue
		, RuleValue
		, NotelpValue
		, DescriptionValue
	) => {
		let vStatus = 1
		let vMessage = []

		try{
			const vURL = vAPIURL + "/Golf/Config/" + UidTemp;
			const vData = {
				Action: {
					Value: "Save"
				},
				Input: {
					Fullname: {
						Value: FullnameValue
					},
					Email: {
						Value: EmailValue
					},
					Rule: {
						Value: RuleValue
					},
					NoTelp: {
						Value: NotelpValue
					},
					Description: {
						Value: DescriptionValue
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "FEditUser Error 1" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "FEditUser Error 2" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				toastr.success( "Save Success" );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
	}

	useEffect(() => {
		FGenUsername()
	}, [])

	useEffect(() => {
		if( Uid != "" ){
			FUserInfo(Uid)
		}
	}, [Uid])

	return (
		<div className="card bg-gradient-light">
			{
				LoadingPage ?
					<div className="d-flex justify-content-center">
						<i className="fas fa-3x fa-sync-alt fa-spin"></i>
					</div>
				:
				<>
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								User Menu Config
							</li>
						</ul>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-3 col-md-1 p-0">
								<div className="form-group m-0">
									<label>
										Username
									</label>
									<a style={{ color: 'red' }}>
										*
									</a>
								</div>
							</div>
							<div className="col-6 col-md-2 p-0">
								<select
									value={ Uid }
									onChange={ (e) => setUid( e.target.value ) }
								>
									{UsernameArrayList.map( ( Value, Index ) => (
										<option key={Index} value={Value}>
											{UsernameArrayListCode[Index]}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="row">
							<div className="col-3 col-md-1 p-0">
								<div className="form-group m-0">
									<label>
										Fullname
									</label>
									<a style={{ color: 'red' }}>
										*
									</a>
								</div>
							</div>
							<div className="col-6 col-md-2 p-0">
								<input
									value={ Fullname }
									onChange={ (e) => setFullname( e.target.value ) }
								>
								</input>
							</div>
						</div>
						<div className="row">
							<div className="col-3 col-md-1 p-0">
								<div className="form-group m-0">
									<label>
										Email
									</label>
									<a style={{ color: 'red' }}>
										*
									</a>
								</div>
							</div>
							<div className="col-6 col-md-2 p-0">
								<input
									value={ Email }
									onChange={ (e) => setEmail( e.target.value ) }
								>
								</input>
							</div>
						</div>
						<div className="row">
							<div className="col-3 col-md-1 p-0">
								<div className="form-group m-0">
									<label>
										Rule
									</label>
									<a style={{ color: 'red' }}>
										*
									</a>
								</div>
							</div>
							<div className="col-6 col-md-2 p-0">
								<select
									value={ Rule }
									onChange={ (e) => setRule( e.target.value ) }
								>
									{RuleArrayList.map( ( Value, Index ) => (
										<option key={Index} value={Value}>
											{RuleArrayListCode[Index]}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="row">
							<div className="col-3 col-md-1 p-0">
								<div className="form-group m-0">
									<label>
										No Telp
									</label>
									<a style={{ color: 'red' }}>
										*
									</a>
								</div>
							</div>
							<div className="col-6 col-md-2 p-0">
								<input
									value={ NoTelp }
									onChange={ (e) => setNoTelp( e.target.value ) }
								>
								</input>
							</div>
						</div>
						<div className="row">
							<div className="col-3 col-md-1 p-0">
								<div className="form-group m-0">
									<label>
										Description
									</label>
								</div>
							</div>
							<div className="col-6 col-md-2 p-0">
								<input
									value={Description}
									onChange={ (e) => setDescription( e.target.value ) }
								>
								</input>
							</div>
						</div>
						<div className="row">
							<div className="col-3 col-md-1 p-0">
								<div className="form-group m-0">
									<label>
										Status
									</label>
								</div>
							</div>
							<div className="col-6 col-md-2 p-0">
								<input 
									value={StatusUser}
									disabled
								>
								</input>
							</div>
						</div>
					</div>
					<div className="card-footer">
						<button
							type="submit"
							onClick={ () => {
								FEditUser(
									Uid
									, Fullname
									, Email
									, Rule
									, NoTelp
									, Description
								);
							} }
							className="btn btn-primary"
							style={{ paddingTop:1, paddingBottom:1 }}
						>
							Save
						</button>
					</div>
				</>
			}
		</div>
	)
}

export default UserConfig