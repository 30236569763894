import React, { useEffect, useState } from 'react'
import YearlyDate from './YearlyDate';
import { vAPIURL, vBoolDev, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import format from 'date-fns/format';

const TableRoomYearly = () => {

	const [TotalQtyYearly, SetTotalQtyYearly] = useState([]);
	const [loading, setLoading] = useState(false)

	const GetTotalQtyYearly = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Reservation/RoomYearly"
			const vData = {
				Action: {
					Value: "Search"
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							SetTotalQtyYearly( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch(vError){
			vStatus = 2;
			vMessage.push( "Error" );
		}
		
		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}

		setLoading( false );
	}
	
	let DataVVIP = TotalQtyYearly.map(row => row.VVIP)

	let SumVVIP = 0
	DataVVIP.forEach(Value => {
		SumVVIP += parseInt(Value);
	});
	DataVVIP.push(SumVVIP);

	let DataVIP1 = TotalQtyYearly.map(row => row.VIP1)

	let SumVIP1 = 0
	DataVIP1.forEach(Value => {
		SumVIP1 += parseInt(Value);
	});
	DataVIP1.push(SumVIP1);

	let DataVIP2 = TotalQtyYearly.map(row => row.VIP2)

	let SumVIP2 = 0
	DataVIP2.forEach(Value => {
		SumVIP2 += parseInt(Value);
	});
	DataVIP2.push(SumVIP2);
	
	let DataVIP3 = TotalQtyYearly.map(row => row.VIP3)

	let SumVIP3 = 0
	DataVIP3.forEach(Value => {
		SumVIP3 += parseInt(Value);
	});
	DataVIP3.push(SumVIP3);

	let DataVIP4 = TotalQtyYearly.map(row => row.VIP4)

	let SumVIP4 = 0
	DataVIP4.forEach(Value => {
		SumVIP4 += parseInt(Value);
	});
	DataVIP4.push(SumVIP4);

	let DataVIP5 = TotalQtyYearly.map(row => row.VIP5)

	let SumVIP5 = 0
	DataVIP5.forEach(Value => {
		SumVIP5 += parseInt(Value);
	});
	DataVIP5.push(SumVIP5);

	let DataBallRoom = TotalQtyYearly.map(row => row.BALLROOM)

	let SumBallRoom = 0
	DataBallRoom.forEach(Value => {
		SumBallRoom += parseInt(Value);
	});
	DataBallRoom.push(SumBallRoom);

	const HeaderTable = [ "JAN", 'FEB', "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC", "TOTAL" ]

	useEffect(() => {
		GetTotalQtyYearly()
	}, []) 

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info font-weight-bold">
						ROOM USAGE TABLE YEAR TO DATE
					</li>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info">
						(In Qty)
					</li>
				</ul>
				<div>
					<YearlyDate />
				</div>
			</div>
			<div className="card-body">
				<div className="tab-content p-0 ">
					{loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<>
							<div>
								<TableContainer>
									<Table aria-label="simple table">
										<TableHead>
											<TableCell 
												sx={{ 
													border: 1
													, width: 200
													, fontWeight: 'bold'
													, padding: 0.5
												}}
											>
												ROOM
											</TableCell>
											{HeaderTable.map((Value) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, fontWeight: 'bold'
														, padding: 0.5 
													}} 
													align='center'
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell 
												sx={{ 
													border: 1
													, width: 200
													, fontWeight: 'bold'
													, padding: 0.5 
												}}
											>
												{
													vBoolDev == true 
														?
														'VIP1'
														:
														'VVIP - MINANGKABAU'
												}
											</TableCell>
											{DataVVIP.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell 
												sx={{ 
													border: 1
													, width: 200
													, fontWeight: 'bold'
													, padding: 0.5 
												}}
											>
												{
													vBoolDev == true 
														?
														'VIP2'
														:
														'VIP1 - YOGYAKARTA'
												}
											</TableCell>
											{DataVIP1.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell 
												sx={{ 
													border: 1
													, width: 200
													, fontWeight: 'bold'
													, padding: 0.5}} 
											>
												{
													vBoolDev == true 
														?
														'VIP3'
														:
														'VIP2 - DENPASAR'
												}
											</TableCell>
											{DataVIP2.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell 
												sx={{
													border: 1
													, width: 200
													, fontWeight: 'bold'
													, padding: 0.5
												}}
											>
												{
													vBoolDev == true 
														?
														'VIP4'
														:
														'VIP3 - BANDA ACEH'
												}
											</TableCell>
											{DataVIP3.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell 
												sx={{
													border: 1
													, width: 200
													, fontWeight: 'bold'
													, padding: 0.5
												}}
											>
												{
													vBoolDev == true 
														?
														'VIP5'
														:
														'VIP4 - MANADO'
												}
											</TableCell>
											{DataVIP4.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell 
												sx={{
													border: 1
													, width: 200
													, fontWeight: 'bold'
													, padding: 0.5
												}}
											>
												{
													vBoolDev == true 
														?
														'VIP6'
														:
														'VIP5 - JAKARTA'
												}
											</TableCell>
											{DataVIP5.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
										<TableHead>
											<TableCell 
												sx={{
													border: 1
													, width: 200
													, fontWeight: 'bold'
													, padding: 0.5 
												}}
											>
												{
													vBoolDev == true 
														?
														'BALLROOM'
														:
														'BALLROOM'
												}
											</TableCell>
											{DataBallRoom.map((Value, index) => (
												<TableCell 
													sx={{ 
														border: 1
														, width: 100
														, padding: 0.5
														, fontWeight:(index === 12 ? 'bold' : '')
													}}
													align={index === 12 ? 'right' : 'center'}
												>
													{Value}
												</TableCell>
											))}
										</TableHead>
									</Table>
								</TableContainer>
							</div>
						</>
					}
				</div>
			</div>
		</div>
	)
}

export default TableRoomYearly