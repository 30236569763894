import { useState, useEffect } from "react";
import React from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { KeyboardArrowDown } from '@mui/icons-material';
import { KeyboardArrowUp } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import format from 'date-fns/format';

const CaddyGroupAttendance = () => {
	const [ CaddyGroupAttendance, SetCaddyGroupAttendance ] = useState( [] );
	const [ loading, setLoading ] = useState( false );

	const GetCaddyGroupAttendance = async () => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try {
			const vURL = vAPIURL + "/Golf/Operation/CaddyStatus";
			const vData = {
				Action: {
					Value: "Search"
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						let i = 0
						for( i ; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[i] );
						}

						if( vStatus === 1 ){
							SetCaddyGroupAttendance( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					(vError) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}
		catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if ( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false )
	}

	useEffect( () => {
		GetCaddyGroupAttendance()
	}, [] )

	const BorderLinearProgress = styled( LinearProgress ) ( ( { theme, vBoolAttendance } ) => ( {
		height: 10
		, borderRadius: 5
		, [`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: vBoolAttendance == 'false' ? theme.palette.grey[200] :'#000000',
		}
		, [`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5
			, backgroundColor: '#1a90ff'
		}
	} ) )

	const Outline = ( { Value, GroupActive } ) => {
		const circleStyle = {
			width: '20px'
			, height: '20px'
			, borderRadius: '50%'
			, boxSizing: 'border-box'
			, position: 'flex'
			, backgroundColor: GroupActive == "false" ? 'green' : 'red'
			, zIndex: 1
			, color: 'white'
			, justifyContent: 'center'
			, alignItems: 'center'
		};
	  
		return (
		 	<div style={circleStyle}>
				{Value}
			</div>
		);
	}

	function TableContent( props ) {
		const { TableValue } = props;
		const [ open, setOpen ] = useState( false );
	
		return (
			<tbody>
				<tr>
					<td className="column-cga-1">
						<div className="d-flex justify-content-center">
							<Outline Value={TableValue.GroupCaddy} GroupActive={TableValue.ActiveGroup}/>
						</div>
					</td>
					<td className="column-cga-2">
						{TableValue.CaptainName}
					</td>
					<td className="column-cga-3">
		 				<IconButton
		 					aria-label="expand row"
		 					size="small"
		 					onClick={ () => setOpen( !open ) }
		 				>
		 					{
								open ? 
		 						<KeyboardArrowUp /> 
		 						: 
		 						<KeyboardArrowDown />
		 					}
		 				</IconButton>
					</td>
					<td className="column-cga-4">
						<BorderLinearProgress variant="determinate" value={TableValue.Percentage} vBoolAttendance={TableValue.ActiveGroup}/>
					</td>
					<td className="column-cga-5">
						{TableValue.Percentage} %
					</td>
				</tr>
				<tr>
					<td className="p-0" colSpan={5}>
						<Collapse
							in={open}
							timeout="auto"
							unmountOnExit
						>
							<div className="d-flex justify-content-center">
								<div style={{ width: '95%' }}>
									<table style={{ width: '100%' }}>
										<thead>
											<tr>
												<td className="column-cga-cn" colSpan={2}>
													Caddy Name
												</td>
												<td className="column-cga-sts">
													Status
												</td>
											</tr>
										</thead>
										<tbody>
											{TableValue.StatusCaddy.map( ( Value2, Index2 ) => (
												<tr>
													<td className="column-cga-6">
														{Index2 +1}
													</td>
													<td className="column-cga-7">
														{Value2.Name}
													</td>
													<td className="column-cga-8">
														<Box 
															align="center"
															sx={{ 
																width: 100
																, backgroundColor: 
																	Value2.Status == "StandBy" ? "success.main" :
																	Value2.Status == "Absent" ? "error.main" :
																	Value2.Status == "OnCourse" ? "primary.main" : 
																	Value2.Status == "CheckOut" ? "error.main" : "warning.main"
																, color: 
																	Value2.Status == "Paired" ? "black" : "white"
															}}
														>
															{Value2.Status}
														</Box>
													</td>
												</tr>
												) )
											}
										</tbody>
									</table>
								</div>
							</div>
						</Collapse>
					</td>
				</tr>
			</tbody>
		);
	}

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<h3 className="card-title text-success font-weight-bold">
					CADDY GROUP ATTENDANCE
				</h3>
				<div className="card-tools">
					<ul className="nav nav-pills ml-auto">
						<li className="nav-item text-info font-weight-bold">
							TODAY
						</li>
					</ul>
				</div>
			</div>
			{
				loading ?
				<div className="d-flex justify-content-center">
					<i className="fas fa-3x fa-sync-alt fa-spin"/>
				</div>
				:
				<table>
					<thead>
						<tr>
							<td className="column-cga-group">
								GROUP
							</td>
							<td className="column-cga-captain" colSpan={2}>
								CAPTAIN
							</td>
							<td className="column-cga-attendance" colSpan={2}>
								ATTENDANCE
							</td>
						</tr>
					</thead>
						{CaddyGroupAttendance.map( ( Value ) => (
							<TableContent key={Value.GroupCaddy} TableValue={Value}/>
							) )
						}
				</table>
			}
		</div>
	);
}

export default CaddyGroupAttendance