import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { vAPIURL, vGlobalDateNow } from "../../Global";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import 'chart.js/auto';
import "react-datepicker/dist/react-datepicker.css";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DatePicker from 'react-datepicker';
import format from 'date-fns/format';
import { startOfWeek, endOfWeek } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';

ChartJS.register(...registerables )

const FoodChart = () => {
	const GlobalDate = new Date( vGlobalDateNow )
	const [ Loading, setLoading ] = useState( false )
	const [ FnBData, setFnBData ] = useState( [] )
	const [ EndDate, SetEndDate ] = useState( endOfWeek( GlobalDate, { weekStartsOn: 1 } ) );
	const [ StartDate, SetStartDate ] = useState( new Date( startOfWeek( EndDate, { weekStartsOn: 1 } ) ) );

	const GetFnBData = async() => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/FnB/Food"
			const vData = {
				Action: {
					Value: "Search"
				},
				Input: {
					StartDate: {
						Value: format( StartDate, 'dd-MMM-yyyy' ),
					},
					EndDate: {
						Value: format( EndDate, 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						for( let i = 1 ; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setFnBData( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false );
	}

	useEffect(() => {
		GetFnBData()
	},[])

	let ChartData = {
		labels: FnBData.map( Value => Value.Date ),
		datasets: [
			{
				label: 'FOOD'
				, data: FnBData.map( Value => Value.Qty )
				, backgroundColor: 'rgb( 0, 123, 255 )'
				, borderColor: 'rgb( 0, 123, 255 )'
				, borderWidth: 2
			},
		],
	};

	let ChartOption = {
		maintainAspectRatio: false
		, responsive : true
		, scales: {
			x: {
				beginAtZero: true
			},
			y: {
				beginAtZero: true
			},
		}
		, plugins: {
			legend: {
				display: true
				, position: 'top'
				, align: 'center'
				, labels: {
					boxHeight: 10
					, boxWidth: 10
					, textAlign: 'left'
				}
			}
			, tooltip: {
				callbacks: {
					title: function(tooltipItem, data) {
						return '';
					}
				}
			}
			, datalabels: {
				anchor: 'center'
				, align: 'center'
				, display: true
				, font: {
					size: 10
				}
			}
		}
		, onHover: (e, ChartElement) => {
			e.native.target.style.cursor = ChartElement[0] ? 'pointer' : 'default'
		},
	}

	return (
		<div className="col-12">
			<div className="card bg-gradient-light">
				<div className="card-header">
					<h3 className="card-title text-success font-weight-bold">
						FOOD IN PORTIONS
					</h3>
					<div className="card-tools">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
							</li>
						</ul>
					</div>
				</div>
				<div className="CardBody-Chart">
					<div className="row">
						<div className="col-2">
							<div className="form-group">
								<label>
									START DATE
								</label>
								<DatePicker
									dateFormat="dd-MMM-yyyy"
									selected={ StartDate }
									onChange={e => SetStartDate(e)}
									className="form-control"
									value={ StartDate }
									locale={ enGB }
								/>
							</div>
						</div>
						<div className="col-2">
							<div className="form-group">
								<label>
									END DATE
								</label>
								<DatePicker
									dateFormat="dd-MMM-yyyy"
									selected={ EndDate }
									onChange={ e => SetEndDate(e) }
									className="form-control"
									value={ EndDate }
									locale={ enGB }
								/>
							</div>
						</div>
						<div className="col-2">
							<button
								onClick={GetFnBData}
								type="submit"
								className="btn btn-primary float-right"
								style={{ marginTop:"31px" }}
							>
								<i className="fas fa-search"/>
								&nbsp;
								{Loading ?
										"Please wait..."
									:
										"Search"
								}
							</button>
						</div>
					</div>
					<div className="tab-content p-0">
						{	
							Loading ?
								<div className="d-flex justify-content-center">
									<i className="fas fa-3x fa-sync-alt fa-spin"/>
								</div>
							:
								<div className="Chart-Container">
									<Bar
										data={ChartData}
										plugins={[ChartDataLabels]}
										options={ChartOption}
										height={null}
										width={null}
									/>
								</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default FoodChart