import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MonthlyDate from '../Reservation/MonthlyDate';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import TablePaginationUnstyled, { tablePaginationUnstyledClasses as classes } from '@mui/base/TablePaginationUnstyled';
import { styled } from '@mui/system';
import format from 'date-fns/format';

const FoodOverUnderDuration = () => {

	const [ItemOverTemp, SetItemOverTemp] = useState( [] );
	const [ItemUnderTemp, SetItemUnderTemp] = useState( [] );
	const [ItemOver, SetItemOver] = useState( [] );
	const [ItemUnder, SetItemUnder] = useState( [] );
	const [loading, setLoading] = useState( false );
	const [ArrayLength, setArrayLength] = useState( 0 );

	function CheckItemOver( { TimeDuration, AvgActual } ){
		return parseInt( TimeDuration ) < parseInt( AvgActual );
	}

	function CheckItemUnder( { TimeDuration, AvgActual } ){
		return parseInt( TimeDuration ) >= parseInt( AvgActual );
	}

	const GetItemDuration = async () => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/FnB/AverageTiming"
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Type: {
						Value: "Food"
					},
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				}
			}
			const vConfig = {
				headers: {
					"Content-Type": "application/json"
					, Authentication: `${localStorage.getItem("Authentication")}`
				}
			}

			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] )
						}

						if( vStatus === 1 ){
							SetItemOverTemp( vResponse.data.Output.Result.filter( CheckItemOver ) )
							SetItemUnderTemp( vResponse.data.Output.Result.filter( CheckItemUnder ) )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2
						vMessage.push( "Error" )
					}
				)
		}catch( vError ){
			vStatus = 2
			vMessage.push( "Error" )
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) )
			}
			else{
				toastr.error( vMessage.join( "</br>" ) )
			}
		}
		setLoading( false )
	}

	function FormatData( MenuName, Qty, AvgActual, TimeDuration, Ordering ){
		return { MenuName, Qty, AvgActual, TimeDuration, Ordering }
	}

	const CustomTablePagination = styled(TablePaginationUnstyled)`
		& .${classes.toolbar} {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 7px;

			@media (min-width: 768px) {
				flex-direction: row;
				align-items: center;
			}
		}

		& .${classes.selectLabel} {
			margin: 0;
			gap: 7px;
		}

		& .${classes.displayedRows} {
			margin: 0;
			gap: 7px;

			@media (min-width: 768px) {
				margin-left: auto;
			}
		}

		& .${classes.spacer} {
			display: none;
		}

		& .${classes.actions} {
			display: flex;
			gap: 0.25rem;
		}
	`;

	useEffect( () => {
		if( ItemOverTemp.length >= ItemUnderTemp.length ){
			setArrayLength( ItemOver.length )

			const ItemTemp1 = Array.from( { length: ItemOver.length }, (_, index) => {
				const Menu = ItemUnderTemp[index]?.MenuName || ''
				const Qty = ItemUnderTemp[index]?.Qty || ''
				const Avg = ItemUnderTemp[index]?.AvgActual || ''
				const Time = ItemUnderTemp[index]?.TimeDuration || ''
				const Order = index + 1;
				return FormatData( Menu, Qty, Avg, Time, Order );
				}
			)

			const ItemTemp2 = ItemUnderTemp.map( ( Value, Index ) => {
				const Ordering = Index + 1
				const { MenuName, Qty, AvgActual, TimeDuration } = Value
				return FormatData( MenuName, Qty, AvgActual, TimeDuration, Ordering )
				}
			)
			SetItemOver( ItemTemp2 )
		 	SetItemUnder( ItemTemp1 )
		}
		else{
			setArrayLength( ItemUnderTemp.length )

			const ItemTemp1 = Array.from({ length: ItemUnderTemp.length }, (_, index) => {
				const Menu = ItemOverTemp[index]?.MenuName || ''
				const Qty = ItemOverTemp[index]?.Qty || ''
				const Avg = ItemOverTemp[index]?.AvgActual || ''
				const Time = ItemOverTemp[index]?.TimeDuration || ''
				const Order = index + 1;
				return FormatData( Menu, Qty, Avg, Time, Order )
				}
			)
			
			const ItemTemp2 = ItemUnderTemp.map( ( Value, Index ) => {
				const Ordering = Index + 1
				const { MenuName, Qty, AvgActual, TimeDuration } = Value
				return FormatData( MenuName, Qty, AvgActual, TimeDuration, Ordering )
				}
			)

			SetItemOver( ItemTemp1 )
			SetItemUnder( ItemTemp2 )
		}
	}, [ ItemOverTemp, ItemUnderTemp ] );

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [StartCount, SetStartCount] = useState(1);
	const [EndCount, SetEndCount] = useState(rowsPerPage);

	const handleChangePage = (event, newPage) => {setPage(newPage);
		if ( page < newPage ){
			if ( page + 1 == newPage ){
				SetStartCount((StartCount + rowsPerPage));
				if ( (EndCount + rowsPerPage) > ArrayLength ){
					SetEndCount(ArrayLength);
				}
				else{
					SetEndCount((EndCount + rowsPerPage));
				}
			}
			else{
				SetStartCount( (newPage * rowsPerPage) + 1 );
				SetEndCount(ArrayLength);
			}
		}
		else if ( page > newPage ){
			if ( page - 1 == newPage ){
				SetStartCount((StartCount - rowsPerPage));
				if ( (newPage + 1) * rowsPerPage > ArrayLength ){
					SetEndCount(ArrayLength);
				}
				else{
					SetEndCount((newPage + 1) * rowsPerPage);
				}
			}
			else{
				SetStartCount(1);
				SetEndCount(rowsPerPage);
			}
		}
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		SetStartCount(1);
		if (event.target.value == -1){
			SetEndCount(ArrayLength);
		}
		else{
			SetEndCount(parseInt(event.target.value));
		}
		setPage(0);
	};

	useEffect(() => {
		GetItemDuration()
	}, [])

	return (
		<div className="row p-1">
			<div className="col-sm-12 col-xl-6 p-1">
				<div className="card bg-gradient-light">
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<h3 className="card-title text-success font-weight-bold">
								MTD FBP COOKING TIME - OVERDURATION
							</h3>
						</ul>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info">
								(In Qty)
							</li>
						</ul>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info">
								<MonthlyDate />
							</li>
						</ul>
						<CustomTablePagination
							rowsPerPageOptions={[5, 10, 20, { label: 'All', value: -1 }]}
							count={ArrayLength}
							rowsPerPage={rowsPerPage}
							page={page}
							slotProps={{
								select: {
								'aria-label': 'rows per page',
								},
								actions: {
									showFirstButton: true,
									showLastButton: true,
								},
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</div>
					{loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<div>
							<TableContainer component={Paper}>
								<Table aria-label="Table Timing">
									<TableHead sx={{ minHeight:10 }}>
										<TableRow>
											<TableCell sx={{ border: 1, width: 20, fontWeight: 'bold', padding: 0.5  }} align="center">
												#
											</TableCell>
											<TableCell sx={{ border: 1, width: 240, fontWeight: 'bold', padding: 0.5  }} align="left">
												Item
											</TableCell>
											<TableCell sx={{ border: 1, width: 100, fontWeight: 'bold', padding:0.5 }} align="center">
												Standard Duration
											</TableCell>
											<TableCell sx={{ border: 1, width: 100, fontWeight: 'bold', padding:0.5 }} align="center">
												Actual <br/>Time
											</TableCell>
											<TableCell sx={{ border: 1, width: 60, fontWeight: 'bold', padding:0.5 }} align="center">
												Qty
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											(
												rowsPerPage > 0
												? ItemOver.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
												: ItemOver
											).map( ( Value, Index ) => (
												<TableRow>
													<TableCell sx={{ border: 1, padding: 0.5 }} align="center">
														{Value.Ordering}
													</TableCell> 
													<TableCell sx={{ border: 1, padding:0.5 }} align="left">
														{Value.MenuName}
													</TableCell>
													<TableCell sx={{ border: 1, padding: 0.5 }} align="center">
														{Value.TimeDuration}"
													</TableCell>
													<TableCell sx={{ border: 1, padding: 0.5, backgroundColor:'rgb(198,40,40)' }} align="center">
														{Value.AvgActual}"
													</TableCell>
													<TableCell sx={{ border: 1, padding:0.5 }} align="center">
														{Value.Qty}
													</TableCell>
												</TableRow>
												)
											)
										}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					}
				</div>
			</div>
			<div className="col-sm-12 col-xl-6 p-1">
				<div className="card bg-gradient-light">
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<h3 className="card-title text-success font-weight-bold">
								MTD FBP COOKING TIME - UNDERDURATION
							</h3>
						</ul>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info">
								(In Qty)
							</li>
						</ul>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info">
								<MonthlyDate />
							</li>
						</ul>
						<ul className="nav nav-pills ml-auto" style={{ height: 29 }}>
							<li className="nav-item justify-content-center" style={{ paddingTop: 5 }}>
								Rows per page: {StartCount} - {EndCount} of {ArrayLength}
							</li>
						</ul>
					</div>
					{loading ?
						<div className="d-flex justify-content-center">
								<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<TableContainer component={Paper}>
							<Table aria-label="Table Timing">
								<TableHead sx={{ minHeight:10 }}>
									<TableRow>
										<TableCell sx={{ border: 1, width: 20, fontWeight: 'bold', padding:0.5 }} align="center">
											#
										</TableCell>
										<TableCell sx={{ border: 1, width: 240, fontWeight: 'bold', padding:0.5 }} align="left">
											Item
										</TableCell>
										<TableCell sx={{ border: 1, width: 100, fontWeight: 'bold', padding:0.5 }} align="center">
											Standard Duration
										</TableCell>
										<TableCell sx={{ border: 1, width: 100, fontWeight: 'bold', padding:0.5 }} align="center">
											Actual <br/>Time
										</TableCell>
										<TableCell sx={{ border: 1, width: 60, fontWeight: 'bold', padding:0.5 }} align="center">
											Qty
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										(
											rowsPerPage > 0
											? ItemUnder.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											: ItemUnder
										).map( ( Value, Index ) => (
												<TableRow>
													<TableCell sx={{ border: 1, padding:0.5 }} align="center">
														{Value.Ordering}
													</TableCell> 
													<TableCell sx={{ border: 1, padding:0.5 }} align="left">
														{Value.MenuName}
													</TableCell>
													<TableCell sx={{ border: 1, padding:0.5 }} align="center">
														{Value.TimeDuration}"
													</TableCell>
													<TableCell sx={{ border: 1, padding:0.5, backgroundColor:'rgb(76,175,80)' }} align="center">
														{Value.AvgActual}"
													</TableCell>
													<TableCell sx={{ border: 1, padding:0.5 }} align="center">
														{Value.Qty}
													</TableCell>
												</TableRow>
											)
										)
									}
								</TableBody>
							</Table>
						</TableContainer>
					}
				</div>
			</div>
		</div>
	);
}
export default FoodOverUnderDuration 