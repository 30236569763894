import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import format from 'date-fns/format';

ChartJS.register(...registerables )

const LastWeekCaddyAttendance = () => {
	const [ CaddyAttendance, SetCaddyAttendance ] = useState( [] );
	const [ loading, setLoading ] = useState( false );
	const CaddyAttendanceCheckin = CaddyAttendance.map( row => row.CaddyCheckin ).slice( 0, 7 );
	const CaddyAttendanceAbsent = CaddyAttendance.map( row => row.CaddyAbsent ).slice( 0, 7 );

	let data = {
		labels: [ 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN' ]
		, datasets: [
			{
				label: 'ABSENT'
				, data: CaddyAttendanceAbsent
				, backgroundColor: 'rgb( 209, 37, 21 )'
				, borderColor: 'rgb( 209, 37, 21 )'
				, borderWidth: 1
			}
			,{
				label: 'PRESENT'
				, data: CaddyAttendanceCheckin
				, backgroundColor: 'rgb( 0, 176, 80 )'
				, borderColor: 'rgb( 0, 176, 80 )'
				, borderWidth: 1
			}
		]
	}

	let option = {
		maintainAspectRatio: false,
		responsive : true,
		scales: {
			x: {
				beginAtZero: true
			},
			y: {
				beginAtZero: true
			}
		},
		plugins: {
			legend: {
				display: true,
				position: 'bottom',
				align: 'center',
				labels: {
					boxHeight: 5,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			tooltip: {
				callbacks: {
					title: function( tooltipItem, data ) {
						return '';
					}
				}
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				display: 'true',
				font: {
					size: 8,
				},
				color: function( context ) {
					const index = context.datasetIndex;
					return index === 0 ? 'white' : 'black'
				}
			}
		}
	}

	const GetCaddyAttendance = async () => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try {
			const vURL = vAPIURL + "/Golf/Operation/Caddy";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios 
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						let i = 0
						for( i ; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[i] );
						}

						if( vStatus === 1 ){
							SetCaddyAttendance( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}
		catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}
		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false )
	}

	useEffect( () => {
		GetCaddyAttendance()
	}, [] )

	return (
		<div className="col-12 col-lg-6">
			<div className="card bg-gradient-light">
				<div className="card-header">
					<h3 className="card-title text-success font-weight-bold">
						CADDY ATTENDANCE
					</h3>
					<div className="card-tools">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								(LAST WEEK)
							</li>
						</ul>
					</div>
				</div>
				<div className="card-body">
					<div className="tab-content p-0 ">
						{loading ?
							<div className="d-flex justify-content-center">
								<i className="fas fa-3x fa-sync-alt fa-spin"></i>
							</div>
							:
							<div className="Chart-Container">
								<Bar
									data={data}
									plugins={[ChartDataLabels]}
									options={option}
									height={null}
									width={null}
								/>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default LastWeekCaddyAttendance