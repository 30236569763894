import React, { useEffect, useState } from "react"
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import { vBoolDev, vAPIURL } from "../Global";
import axios from "axios";
import toastr from "toastr";

const Layout = ( { children } ) => {
	const [ MenuDataTemp, setMenuDataTemp ] = useState( [] )
	const [ ProfileDataTemp, setProfileDataTemp ] = useState( [] )
	const [ LoadingMenu, setLoadingMenu ] = useState( true )
	const [ LoadingProfile, setLoadingProfile ] = useState( true )
	const [ ImageProfile, setImageProfile ] = useState( "" )
	const [ UserRoleTemp, setUserRoleTemp ] = useState( "" )
	
	const FGenerateMenu = async () => {
		let vStatus = 1
		let vMessage = []
		setLoadingMenu( true )

		try{
			const vURL = vAPIURL + "/Golf/Menu/Generate";
			const vData = {
				Action: {
					Value: "Generate"
				},
				Input: {
					Mode: {
						Value: 2
					},
					Application: {
						Value: "DASH"
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setMenuDataTemp( vResponse.data.Output.WithParent )
							setUserRoleTemp( vResponse.data.Rule.Code )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}

		setLoadingMenu( false )
	}
	
	const FGetProfilePictureDownload = () => {
		let vStatus = 1
		let vMessage = []
		setLoadingProfile( true )

		try{
			const vURL = vAPIURL + "/Golf/Profile/Picture/" + ProfileDataTemp.Picture.Value;
			const vData = {};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
				responseType: 'blob' 
			};
			axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						if( typeof vResponse.data.Status !== 'undefined' ){
							vStatus = 1
						}
						else{
							vStatus = vResponse.data.Status
						}

						if( vStatus === 2 ){
							for( let i = 0; i < vResponse.data.Message.length; i++ ){
								vMessage.push( vResponse.data.Message[ i ] );
							}
						}
						else{
							const imageBlob = vResponse.data;
							const reader = new FileReader();
							
							reader.onload = () => {
								const base64Image = reader.result;
								setImageProfile(base64Image)
								localStorage.setItem( "ProfilePicture", base64Image );
							};

							reader.readAsDataURL(imageBlob);
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2
						vMessage.push( "Error" )
					}
				);
		}catch( vError ){
			vStatus = 2
			// vMessage.push( "Error" )
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoadingProfile( false )
	}

	const FGetProfile = async () => {
		let vStatus = 1
		let vMessage = []

		try{
			const vURL = vAPIURL + "/Golf/Profile";
			const vData = {
				Action: {
					Value: ""
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setProfileDataTemp( vResponse.data.Input )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
	}

	useEffect(() => {
		FGenerateMenu()
		FGetProfile()
	}, [])

	useEffect(() => {
		if( ProfileDataTemp != [] ){
			FGetProfilePictureDownload()
		}
	}, [ProfileDataTemp])

	return (
		<>
			<Header ProfileData={ProfileDataTemp} ImageProfile={ImageProfile} UserRole={UserRoleTemp}/>
			<Menu MenuData={MenuDataTemp} LoadingMenu={LoadingMenu}/>
			<div className="content-wrapper">
				{ children }
			</div>
			<Footer/>
		</>
	);
}

export default Layout