import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import Clouds from '../../Assets/WeatherIcon/clouds.gif';
import Cloudy from '../../Assets/WeatherIcon/cloudy.gif';
import Sun from '../../Assets/WeatherIcon/sun.gif';
import Foggy from '../../Assets/WeatherIcon/foggy.gif';
import Drizzle from '../../Assets/WeatherIcon/drizzle.gif';
import Cold from '../../Assets/WeatherIcon/cold.gif';
import SlightRain from '../../Assets/WeatherIcon/slight-rain.gif';
import Rain from '../../Assets/WeatherIcon/rain.gif';
import Snow from '../../Assets/WeatherIcon/snow.gif';
import ThunderRain from '../../Assets/WeatherIcon/thunder-rain.gif';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vGlobalDateNow } from '../../Global';
import { addHours, differenceInMilliseconds, set } from 'date-fns';

ChartJS.register(...registerables )

const Weather = () => {
	const [ Loading, setLoading ] = useState( true )
	const [ Latitude, setLatitude ] = useState( -6.2835402 )
	const [ Longitude, setLongitude ] = useState( 106.8925516,16 )
	const [ CurrentWeatherDataTemp, setCurrentWeatherDataTemp ] = useState( [] )
	const [ DailyWeatherDataTemp, setDailyWeatherDataTemp ] = useState( [] )
	const [ DataLine, setDataLine ] = useState( [] )
	const [ DataTemperature, setDataTemperature ] = useState( [] )
	const [ Time, setTime ] = useState( format( new Date(), 'HH:mm' ) )
	const TodayDate = format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ).toUpperCase()

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime( format( new Date(), 'HH:mm' ) );
		}, 1000);
		return () => clearInterval( intervalId );
	}, []);

	const WindDirection = ( degrees ) => {
		const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
		const index = Math.round((degrees % 360) / 45) % 8;
		return directions[index];
	}

	const WeatherStatus = ( Code ) => {
		const WMOCode = {
			0: 'Clear'
			, 1: 'Mainly Clear'
			, 2: 'Partly Cloudy'
			, 3: 'Overcast'
			, 45: 'Fog'
			, 48: 'Depositing Rime Fog'
			, 51: 'Light Drizzle'
			, 53: 'Moderate Drizzle'
			, 55: 'Dense Drizzle'
			, 56: 'Light Freezing'
			, 57: 'Dense Freezing'
			, 61: 'Slight Rain'
			, 63: 'Moderate Rain'
			, 65: 'Heavy Rain'
			, 66: 'Light Freezing Rain'
			, 67: 'Heavy Freezing Rain'
			, 71: 'Slight Snowfall'
			, 73: 'Moderate Snowfall'
			, 75: 'Heavy Snowfall'
			, 77: 'Snowgrain'
			, 80: 'Slight Rainshower'
			, 81: 'Moderate Rainshower'
			, 82: 'Heavy Rainshower'
			, 85: 'Light Snowshowers'
			, 86: 'Heavy Snowshowers'
			, 95: 'Moderate Thunderstorm'
			, 96: 'Heavy Snowshowers'
		};

		const status = WMOCode[Code];

		if( status === undefined ) {
			console.error(`Weather status not found for WMO code: ${Code}`);
		}

		return status;
	}
	
	const DataLineTemp = [
		"05:00"
		, "07:00"
		, "09:00"
		, "11:00" 
		, "13:00"
		, "15:00"
		, "17:00"
		, "19:00"
	]

	const WeatherIcon = ( Code ) => {
		const WMOCode = {
			0: Sun
			, 1: Sun
			, 2: Cloudy
			, 3: Clouds
			, 45: Foggy
			, 48: Foggy
			, 51: Drizzle
			, 53: Drizzle
			, 55: Drizzle // Foggy
			, 56: Cold
			, 57: Cold
			, 61: SlightRain
			, 63: Rain
			, 65: Rain
			, 66: Rain
			, 67: Rain
			, 71: Snow
			, 73: Snow
			, 75: Snow
			, 77: Rain
			, 80: Rain
			, 81: Rain
			, 82: Rain
			, 85: Rain
			, 86: Rain
			, 95: ThunderRain
			, 96: ThunderRain
		};
		const status = WMOCode[Code];
		return <img src={status}></img>;
	}

	const GetWeatherData = async() => {
		setLoading( true )
		let vMessage = []

		try{
			const vURL = "https://api.open-meteo.com/v1/forecast?latitude=" + Latitude + "&longitude=" + Longitude + "&current=temperature_2m,apparent_temperature,weather_code,wind_speed_10m,wind_direction_10m,wind_gusts_10m&hourly=temperature_2m,weather_code&daily=weather_code,apparent_temperature_max,apparent_temperature_min&timezone=Asia%2FSingapore"
			await axios
				.get(vURL)
				.then(
					( vResponse ) => {
						const DataTemperatureTemp = [
							vResponse.data.hourly.temperature_2m[5] // Jam 5
							, vResponse.data.hourly.temperature_2m[7] // Jam 7
							, vResponse.data.hourly.temperature_2m[9] // Jam 9
							, vResponse.data.hourly.temperature_2m[11] // Jam 11
							, vResponse.data.hourly.temperature_2m[13] // Jam 1
							, vResponse.data.hourly.temperature_2m[15] // Jam 3
							, vResponse.data.hourly.temperature_2m[17] // Jam 5 Sore
							, vResponse.data.hourly.temperature_2m[19] // Jam 7 Malem
						]
						
						sessionStorage.setItem( "TemperatureData", JSON.stringify( DataTemperatureTemp ) );
						sessionStorage.setItem( "DailyWeatherData", JSON.stringify( vResponse.data.daily ) );
						sessionStorage.setItem( "CurrentWeatherData", JSON.stringify( vResponse.data.current ) );

						setDataLine( DataLineTemp )
						setDataTemperature( JSON.parse( sessionStorage.getItem( "TemperatureData" ) ) )
						setDailyWeatherDataTemp( JSON.parse( sessionStorage.getItem( "DailyWeatherData" ) ) )
						setCurrentWeatherDataTemp( JSON.parse( sessionStorage.getItem( "CurrentWeatherData" ) ) )
					}
				)
				.catch(
					( vError ) => {
						vMessage.push( "Error" );
					}
				)
		}catch( vError ){
		}

		setLoading( false )
	}

	let TemperatureChartData = {
		labels: DataLine.map( Value => Value )
		, datasets: [
			{
				label: 'TEMPERATURE'
				, data: DataTemperature.map( Value => Value )
				, backgroundColor: 'rgb( 255, 204, 0 )'
				, borderColor: 'rgb( 255, 204, 0 )'
				, borderWidth: 1
				, fill: {
					target: 'origin',
					above: 'rgba( 255, 204, 0, 0.2)',
				}
				, cubicInterpolationMode: 'monotone'
				, tension: 0
			}
		],
	}

	let TemperatureChartOption = {
		maintainAspectRatio: false
		, responsive : true
		, scales: {
			x: {
				display: true
				, grid: {
					display: false
				}
			}
			, y: {
				display: false
				, min: 23
				, suggestedmax: 35
			}
		}
		, plugins: {
			legend: {
				display: false
			}
			, tooltip: {
				callbacks: {
					title: function(tooltipItem, data) {
						return '';
					}
				}
			}
			, datalabels: {
				anchor: 'center'
				, align: 'centercenter'
				, display: 'true'
				, clamp: true
			}
		}
	}

	const setupHourlyDataUpdate = () => {
		setDataLine( DataLineTemp )
		setDataTemperature( JSON.parse( sessionStorage.getItem( "TemperatureData" ) ) )
		setDailyWeatherDataTemp( JSON.parse( sessionStorage.getItem( "DailyWeatherData" ) ) )
		setCurrentWeatherDataTemp( JSON.parse( sessionStorage.getItem( "CurrentWeatherData" ) ) )
		setLoading( false )
		
		const calculateNextHourDelay = () => {
		  const now = new Date();
		  const nextHour = addHours(set(now, { minutes: 0, seconds: 0, milliseconds: 0 }), 1);
		  return differenceInMilliseconds(nextHour, now);
		};
		
		const initialTimeout = setTimeout(async () => {
		  await GetWeatherData();
		  
		  const hourlyInterval = setInterval(async () => {
			await GetWeatherData();
		  }, 3600000);
		  
		  return () => clearInterval(hourlyInterval);
		}, calculateNextHourDelay());
		
		return () => clearTimeout(initialTimeout);
	}

	useEffect(() => {
		if( JSON.parse( sessionStorage.getItem( "TemperatureData" ) ) === null ){
			GetWeatherData()
		}
		else{
			const ReHitAPI = setupHourlyDataUpdate()
			
			return ReHitAPI
		}
	},[])

	return (
		<div className="col-12">
			<div className="card bg-gradient-light">
				<div className="card-header">
					<h3 className="card-title text-success font-weight-bold">
						CURRENT WEATHER
					</h3>
					<div className="card-tools">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								{Time}
							</li>
						</ul>
					</div>
				</div>
				<div className="card-body">
					<div className="tab-content p-0">
						{
							Loading ?
								<div className="d-flex justify-content-center">
									<i className="fas fa-3x fa-sync-alt fa-spin"/>
								</div>
							:
								<div className="row">
									<div className="col-6">
										<center id="MainWeatherImg">
											{WeatherIcon(CurrentWeatherDataTemp.weather_code)}
										</center>
										<div className="d-flex justify-content-center text-center">
											<p className="WeatherTitle">
												{WeatherStatus(CurrentWeatherDataTemp.weather_code).toUpperCase()}
											</p>
										</div>
									</div>
									<div className="col-6">
										<div className="row">
											<div className="col-12">
												<div className="Weather-Font">
													TODAY, {TodayDate}
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12 col-lg-6">
												<div className="spaced-content detail p-1">
													<span className="Weather-Font">
														REALFEEL SHADE™
													</span>
													<span className="Weather-Font">
														{CurrentWeatherDataTemp.apparent_temperature}°
													</span>
												</div>
											</div>
											<div className="col-12 col-lg-6">
												<div className="spaced-content detail p-1">
													<span className="Weather-Font">
														TEMPERATURE
													</span>
													<span className="Weather-Font">
														{CurrentWeatherDataTemp.temperature_2m}°
													</span>
												</div>
											</div>
											<div className="col-12 col-lg-6">
												<div className="spaced-content detail p-1">
													<span className="Weather-Font">
														WIND
													</span>
													<span className="Weather-Font">
														{WindDirection(CurrentWeatherDataTemp.wind_direction_10m)} {CurrentWeatherDataTemp.wind_speed_10m} km/h
													</span>
												</div>
											</div>
											<div className="col-12 col-lg-6">
												<div className="spaced-content detail p-1">
													<span className="Weather-Font">
														WIND GUSTS
													</span>
													<span className="Weather-Font">
														{CurrentWeatherDataTemp.wind_gusts_10m} km/h
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="col-12 pt-2">
										<div>
											<Line
												data={TemperatureChartData}
												plugins={[ChartDataLabels]}
												options={TemperatureChartOption}
												height={100}
											/>
										</div>
									</div>
									<div className="col-12 pt-2">
										<div className="row">
										{
											DailyWeatherDataTemp.time.map( ( Value, Index ) => (
												<div className="col p-xs-0">
													<center className="Weather-Font">
														{format( parseISO(Value), 'EEE' ).toUpperCase()}
													</center>
													<center className="Weather-Font">
														{format( parseISO(Value), 'dd/MM' ).toUpperCase()}
													</center>
													<center id="WeatherImg">
														{WeatherIcon( DailyWeatherDataTemp.weather_code[Index] )}
													</center>
													<center className="Weather-Font">
														{WeatherStatus( DailyWeatherDataTemp.weather_code[Index] ).toUpperCase()}
													</center>
													<center className="Weather-Font">
														{DailyWeatherDataTemp.apparent_temperature_max[Index]}&deg;
													</center>
													<center className="Weather-Font">
														{DailyWeatherDataTemp.apparent_temperature_min[Index]}&deg;
													</center>
												</div>
											) )
										}
										</div>
									</div>
								</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Weather