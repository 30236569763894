import React from "react";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import LockResetIcon from "@mui/icons-material/LockReset";
import LogoutIcon from "@mui/icons-material/Logout";

const ProfilePopUp = ( { ProfileData, ImageProfile } ) => {
	let ProfileName = ""
	if( typeof ProfileData.Name == "undefined" ){
		ProfileName = "Profile not Sync"
	}
	else{
		ProfileName = ProfileData.Name.Value
	}

	return (
		<div style={{
			position: "fixed"
			, right: "20px"
			, backgroundColor: "#ffffff"
			, color: "black"
			, padding: "10px 20px"
			, boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)"
			, zIndex: "999"
		}}>
			<div className="row" style={{ width: '200px' }}>
				<div className="col-3 p-0">
					<Avatar sx={{ width: 50, height: 50 }} src={ImageProfile}/>
				</div>
				<div className="col-9">
					{ProfileName}
				</div>
			</div>
			<div className="row" style={{ width: '200px' }}>
				<div className="col-12">
					<div
						style={{
							display: 'flex'
							, justifyContent: 'center'
							, alignItems: 'flex-start'
						}}
					>
						<Link
							className="nav-link"
							onClick={(e) => window.location.replace("/Sign/Out")}
						>
							<LogoutIcon style={{ color: "red", fontSize: "medium" }}/>
							<span
								className="brand-text font-weight-light"
								style={{ color: "red", marginLeft: "6px", fontSize: "16px" }}
							>
								Sign Out
							</span>
						</Link>
					</div>
				</div>
				<div className="col-12">
					<div
						style={{
							display: 'flex'
							, justifyContent: 'center'
							, alignItems: 'flex-start'
						}}
					>
						<Link
							className="nav-link"
							onClick={(e) => window.location.replace("/Password/Change")}
						>
							<LockResetIcon style={{ color: "red", fontSize: "medium" }}/>
							<span
								className="brand-text font-weight-light"
								style={{ color: "red", marginLeft: "6px", fontSize: "16px" }}
							>
								Change Password
							</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProfilePopUp