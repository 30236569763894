import React, { useEffect, useRef, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import format from 'date-fns/format';
import { startOfMonth, endOfMonth } from 'date-fns';
import { vGlobalDateNow } from "../../Global";

const MonthlyDate = () => {
	const [range, setRange] = useState([
		{
			startDate: startOfMonth( new Date( vGlobalDateNow ) ),
			endDate: new Date( vGlobalDateNow ),
			key: 'selection'
		}
	]);

	const [open, setOpen] = useState(false);
	const refOne = useRef(null);

	useEffect(() => {
		document.addEventListener("keydown", hideOnEscape, false);
		document.addEventListener("click", hideOnclickOutside, false);
		return () => {
			document.removeEventListener("keydown", hideOnEscape, false);
			document.removeEventListener("click", hideOnclickOutside, false);
		}
	}, []);

	const hideOnEscape = (e) => {
		if (e.key === "Escape") {
			setOpen(false);
		}
	}

	const hideOnclickOutside = (e) => {
		if (refOne.current && !refOne.current.contains(e.target)) {
			setOpen(false);
		}
	}

	const formatDateRange = (startDate, endDate) => {
		return `${format(startDate, "dd MMM yyyy")} - ${format(endDate, "dd MMM yyyy")}`;
	}

	const handleRangeChange = (item) => {
		const newStartDate = startOfMonth(item.selection.startDate);
		const newEndDate = endOfMonth(item.selection.startDate);
		setRange([{ startDate: newStartDate, endDate: newEndDate, key: 'selection' }]);
	}

	return (
		<div className='calendarWrap'>
			<div
				className='inputBox'
				onClick={() => setOpen(open => !open)}
				style={{
					color: "#31a5e8",
					border: "none",
					pointerEvents: "none",
				}}
			>
				<span className='inputText'>{formatDateRange(range[0].startDate, range[0].endDate)}</span>
			</div>
			<div className='dateRangeWrapper' ref={refOne}>
				{open &&
					<DateRange
						onChange={handleRangeChange}
						editableDateInputs={true}
						moveRangeOnFirstSelection={false}
						ranges={range}
						months={1}
						direction='horizontal'
						className='calendarElement'
					/>
				}
			</div>
		</div>
	);
}

export default MonthlyDate;