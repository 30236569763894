import { faCalendarAlt, faChartBar, faGolfBallTee, faUtensils } from "@fortawesome/free-solid-svg-icons";
import 'toastr/build/toastr.min.css';
import LockResetIcon from "@mui/icons-material/LockReset";
import LogoutIcon from "@mui/icons-material/Logout";
import { React, useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import PersonIcon from '@mui/icons-material/Person';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

import Golf_BG from './../Assets/Golf_BG.jpg';
import Travel_BG from './../Assets/Travel_BG.png';
import Askara_BG from './../Assets/Askara_BG.png';

import Golf_Logo from './../Assets/Golf_Logo.png';
import Travel_Logo from './../Assets/Travel_Logo.jpg';
import Askara_Logo from './../Assets/Askara_Logo.png';
import HIS_Logo from './../Assets/HIS_Logo.jpg';

import { vBoolGolf, vBoolTravel } from './../Global';
import { Skeleton } from "@mui/material";

const Menu = ( { MenuData, LoadingMenu } ) => {
	let vBG = Askara_BG;
	let vLogo = Askara_Logo;
	let vTitle = "ASKARA";

	if( vBoolGolf && vBoolTravel ){
	}
	else if( vBoolGolf ){
		vBG = Golf_BG;
		vLogo = Golf_Logo;
		vTitle = "GM360";
	}
	else if( vBoolTravel ){
		vBG = Travel_BG;
		vLogo = HIS_Logo;
		vTitle = "ITM";
	}
	
	const circleStyle = {
		width: '12px'
		, height: '12px'
		, borderRadius: '50%'
		, boxSizing: 'border-box'
		, backgroundColor: 'black'
		, zIndex: 1
		, justifyContent: 'center'
		, alignItems: 'center'
	}
	
	const IconMenu = ( TrimmedMenuName ) => {
		let IconMenuTemp = "";
		switch( TrimmedMenuName ){
			case "ExecutiveHighlight":
				IconMenuTemp = <FontAwesomeIcon className="nav-icon" icon={faChartBar} style={{ color: "black" }}/>
				break;
			case "NumberOfPlayer":
				IconMenuTemp = <PersonIcon style={{ color: "black" }}/>
				break;
			case "GolfOperation":
				IconMenuTemp = <FontAwesomeIcon className="nav-icon" icon={faGolfBallTee} style={{ color: "black" }}/>
				break;
			case "Reservation":
				IconMenuTemp = <FontAwesomeIcon className="nav-icon" icon={faCalendarAlt} style={{ color: "black" }}/>
				break;
			case "F&BService":
				IconMenuTemp = <FontAwesomeIcon className="nav-icon" icon={faUtensils} style={{ color: "black" }}/>
				break;
			case "F&BProduction":
				IconMenuTemp = <OutdoorGrillIcon style={{ color: "black" }}/>
				break;
			case "FinancialReport":
				IconMenuTemp = <PaidOutlinedIcon style={{ color: "black" }}/>
				break;
			case "IncomeStatementDashboard":
				IconMenuTemp = <PaidOutlinedIcon style={{ color: "black" }}/>
				break;
			case "BalanceSheetDashboard":
				IconMenuTemp = <PaidOutlinedIcon style={{ color: "black" }}/>
				break;
			case "Revenue":
				IconMenuTemp = <PaidOutlinedIcon style={{ color: "black" }}/>
				break;
			case "":
				break;
		}
		return IconMenuTemp
	}

	function GenerateMenu( MenuData ){
		let ElementTemp = []
		
		const [className, setClassName] = useState(
			() => {
			return localStorage.getItem('menuClass') || '';
			}
		);

		useEffect(() => {
			localStorage.setItem('menuClass', className);
		}, [className]);

		const toggleClassName = () => {
			setClassName( ( prevClass ) => {
				if( prevClass === '' ){
					setTimeout( () => {
						setClassName('menu-is-opening menu-open');
					}, 100 );
				  return 'menu-is-opening';
				}
				else{
					return '';
				}
			});
		};
		
		MenuData.forEach( ( Value, Index ) => {
			if( Value.Child && Value.Child.length > 0 ){
				let ParentElementTemp = []
				let ChildElementTemp = []

				Value.Child.forEach( ( Child, ChildIndex ) => {
					const LinkTemp = "/" + Child.Code.replace(/\s/g, '')
					ChildElementTemp.push(
						<li key={ChildIndex} className="nav-item">
							<Link to={LinkTemp} className="nav-link">
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={circleStyle}>
									</div>
									<p style={{ marginLeft: '8px', color: "black" }}>
										{Child.Code}
									</p>
								</div>
							</Link>
						</li>
					)
				})
				
				ParentElementTemp.push(
					<li key={Index} className={`nav-item ${className}`} onClick={toggleClassName}>
						<Link className="nav-link">
							<p
								className="font-weight-bold"
								style={{ color: "black" }}
							>
								{Value.Code}
								<i className="right fas fa-angle-left">
								</i>
							</p>
						</Link>
						<ul className="nav nav-treeview">
							{ChildElementTemp}
						</ul>
					</li>
				)
				ElementTemp.push(ParentElementTemp)
			}
			else{
				const LinkTemp = "/" + Value.Code.replace(/\s/g, '')
				ElementTemp.push(
					<li key={Index} className="nav-item">
						<Link 
							to={LinkTemp}
							className="nav-link"
							onClick={(e) => window.scrollTo(0, 0)}
						>
							{IconMenu( Value.Code.replace( /\s/g, '') ) }
							<p 
								className="brand-text font-weight-bold"
								style={{ color: "black" }}
							>
								{Value.Code}
							</p>
						</Link>
					</li>
				)
			}
		})

		return ElementTemp
	}

	return (
		<div>
			{
				vBoolGolf == true ?
				<aside className="main-sidebar elevation-4" style={{ backgroundImage: `url(${Golf_BG})` }}>
					<Link to={`/`} className="brand-link" style={{ borderBottom: "1px solid black" }}>
						<img
							src={vLogo}
							className="brand-image img-circle elevation-3"
							style={{ opacity: ".8" }}
						/>
						<span className="brand-text font-weight-bold" style={{ color: "black" }}>{vTitle} Dashboard</span>
					</Link>
					<div className="sidebar">
						<nav className="mt-2">
							<ul
								className="nav nav-pills nav-sidebar flex-column"
								data-widget="treeview"
								role="menu"
								data-accordion="false"
							>
								{
									LoadingMenu
									?
									<>
										<Skeleton variant="rounded" height={31} sx={{ marginTop: '3px' }}/>
										<Skeleton variant="rounded" height={31} sx={{ marginTop: '3px' }}/>
										<Skeleton variant="rounded" height={31} sx={{ marginTop: '3px' }}/>
										<Skeleton variant="rounded" height={31} sx={{ marginTop: '3px' }}/>
										<Skeleton variant="rounded" height={31} sx={{ marginTop: '3px' }}/>
										<Skeleton variant="rounded" height={31} sx={{ marginTop: '3px' }}/>
										<Skeleton variant="rounded" height={31} sx={{ marginTop: '3px' }}/>
										<Skeleton variant="rounded" height={31} sx={{ marginTop: '3px' }}/>
									</>
									:
									<>
										{GenerateMenu(MenuData)}
									</>

								}
							</ul>
						</nav>
					</div>
				</aside>
				:
				null
			}
			{
				vBoolTravel == true ?
				<aside className="main-sidebar sidebar-dark-primary elevation-4">
					<Link to={`/`} className="brand-link">
						<img
							src={vLogo}
							className="brand-image img-circle elevation-3"
							style={{ opacity: ".8" }}
						/>
						<span className="brand-text font-weight-light">
							{vTitle} Dashboard
						</span>
					</Link>
					<div className="sidebar">
						<nav className="mt-2">
							<ul
								className="nav nav-pills nav-sidebar flex-column"
								data-widget="treeview"
								role="menu"
								data-accordion="false"
							>
								<li className="nav-item">
									<Link
										className="nav-link"
										onClick={(e) => window.location.replace("/Sign/Out")}
									>
										<LogoutIcon
											style={{ color: "red", marginLeft: 7, fontSize: "medium" }}
										/>
										<span
											className="brand-text font-weight-light"
											style={{ marginLeft: 6, color: "red", fontSize: "16px" }}
										>
											Sign Out
										</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="nav-link"
										onClick={(e) => window.location.replace("/Password/Change")}
									>
										<LockResetIcon
											style={{ color: "red", marginLeft: 6, fontSize: "medium" }}
										/>
										<span
											className="brand-text font-weight-light"
											style={{ marginLeft: 6, color: "red", fontSize: "16px" }}
										>
											Change Password
										</span>
									</Link>
								</li>
								<li className="nav-item">
									<Link
										to={`/SalesManagement`}
										className="nav-link"
										onClick={(e) => window.scrollTo(0, 0)}
									>
										<p> 
											Sales Management
										</p>
									</Link>
								</li>
							</ul>
						</nav>
					</div>
				</aside>
				:
				null
			}
		</div>
	);
};

export default Menu;