const GreenCircle = {
	width: '12px'
	, height: '12px'
	, borderRadius: '50%'
	, boxSizing: 'border-box'
	, backgroundColor: 'green'
	, zIndex: 1
	, justifyContent: 'center'
	, alignItems: 'center'
}

const RedCircle = {
	width: '12px'
	, height: '12px'
	, borderRadius: '50%'
	, boxSizing: 'border-box'
	, backgroundColor: 'red'
	, zIndex: 1
	, justifyContent: 'center'
	, alignItems: 'center'
}

const EmptyCircle = {
	width: '12px'
	, height: '12px'
	, zIndex: 1
	, justifyContent: 'center'
	, alignItems: 'center'
}

export function GenerateCircle( PercentageValue, Top3Index, Bottom3Index, Index ){
	return Top3Index.includes(Index) ? (
		<div className="row">
			<div className="col-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }} >
				<div key={Index} style={GreenCircle}>
				</div>
			</div>
			<div className="col-9" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
				<p>
					{PercentageValue} %
				</p>
			</div>
		</div>
	) 
	:
	Bottom3Index.includes(Index) ?(
		<div className="row">
			<div className="col-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
				<div key={Index} style={RedCircle}>
				</div>
			</div>
			<div className="col-9" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
				<p>
					{PercentageValue} %
				</p>
			</div>
		</div>
	) 
	: (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
			<div key={Index} style={EmptyCircle}>
			</div>
			<p>
				{PercentageValue} %
			</p>
		</div>
	);
}

export function GetTop3Index( Array, ExcludedIndex ){
	const Top3Index = [];
  
	for( let i = 0; i < Array.length; i++ ){
		if( ExcludedIndex.includes(i) ){
			continue;
		}

		if( Top3Index.length < 3 ){
			Top3Index.push(i);
		}
		else{
			let minIndex = 0;

			for( let j = 1; j < 3; j++ ){
				if( Array[Top3Index[j]] < Array[Top3Index[minIndex]] ){
					minIndex = j;
				}
			}
			if( Array[i] > Array[Top3Index[minIndex]] ){
				Top3Index[minIndex] = i;
			}
		}
	}

	return Top3Index;
}

export function GetBottom3Index( Array, ExcludedIndex ) {
	const Bottom3Index = [];

	for( let i = 0; i < Array.length; i++ ){
		if( ExcludedIndex.includes(i) ){
			continue;
		}
		
		if( Bottom3Index.length < 3 ){
			Bottom3Index.push(i);
		} 
		else{
			let maxIndex = 0;

			for( let j = 1; j < Bottom3Index.length; j++ ){
				if( Array[Bottom3Index[j]] > Array[Bottom3Index[maxIndex]] ){
					maxIndex = j;
				}
			}
			if( Array[i] < Array[Bottom3Index[maxIndex]] ){
				Bottom3Index[maxIndex] = i;
			}
		}
	}

	return Bottom3Index;
}

export function GetExcludedIndex( Array ){
	const IndexTemp = [];

	Array.forEach( (Value, Index) => {
		if( Value.Username.toLowerCase() == "hotline" ){
			IndexTemp.push(Index)
		}
		if( Value.Username.toLowerCase() == "tniau" ){
			IndexTemp.push(Index)
		}
	} )

	return IndexTemp;
}
