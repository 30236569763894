import WaitersRank from "./WaitersRank";
import TopItemCategoryChart from "./WeeklyTopCategory";
import CancelItemTableService from "./CancelItemTableService";
import WeeklyTotalSellout from "./WeeklyTotalSelloutChart";
import TopSellingTableFnB from "./TopSellingItemTable1";
import TopSellingTableCnCB from "./TopSellingItemTable2";
import BeverageOverUnderDuration from "./BeverageOverUnderDuration";
import './StyleFnBService.css'

const FnBServices = () => {
	return (
		<div className="container-fluid">
			<div className="content p-3">
				<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
					<div className="col-12">
						<WeeklyTotalSellout />
						<CancelItemTableService />
						<TopItemCategoryChart />
						<WaitersRank />
						<TopSellingTableFnB/>
						<TopSellingTableCnCB/>
						<BeverageOverUnderDuration/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FnBServices