import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import format from 'date-fns/format';

ChartJS.register(...registerables )

const WeeklyNOPChart = () => {
	const [ vWeeklyLoading, SetWeeklyLoading ] = useState( true );
	const [ vWeeklyData, SetWeeklyData ] = useState( [] );

	const FWeekly = async () => {
		SetWeeklyLoading( true );
		let vStatus = 1;
		let vMessage = [];
		try{
			const vURL = vAPIURL + "/Golf/NumberOfPlayer/Weekly";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}
						if( vStatus === 1 ){
							SetWeeklyData( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}
		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		SetWeeklyLoading( false );
	}

	let vWeeklyBarData = {
		labels: [ 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN' ]
		, datasets: [
			{
				label: 'LAST WEEK'
				, data: vWeeklyData.map(Value => Value.TotalPlayer).slice(0, 7)
				, backgroundColor: vWeeklyData.map(Value => Value.Color).slice(0, 7)
				, borderColor: vWeeklyData.map(Value => Value.Color).slice(0, 7)
				, borderWidth: 1 
			}
			, {
				label: 'THIS WEEK'
				, data: vWeeklyData.map(Value => Value.TotalPlayer).slice(7, 14)
				, backgroundColor: vWeeklyData.map(Value => Value.Color).slice(7, 14)
				, borderColor: vWeeklyData.map(Value => Value.Color).slice(7, 14)
				, borderWidth: 1
			}
		]
	};

	let vWeeklyBarOption = {
		maintainAspectRatio: false
		, responsive : true
		, scales: {
			x: {
				beginAtZero: true
			}
			, y: {
				beginAtZero: true
			}
		},
		plugins: {
			legend: {
				display: true,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 10,
					boxWidth: 10,
					textAlign: 'left',
					style: 'bold'
				}
			},
			tooltip: {
				callbacks: {
					label: ((tooltipItem, data) => {
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 0) {
							return vWeeklyData.map(row => row.Date).slice(0, 1);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 1) {
							return vWeeklyData.map(row => row.Date).slice(1, 2);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 2) {
							return vWeeklyData.map(row => row.Date).slice(2, 3);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 3) {
							return vWeeklyData.map(row => row.Date).slice(3, 4);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 4) {
							return vWeeklyData.map(row => row.Date).slice(4, 5);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 5) {
							return vWeeklyData.map(row => row.Date).slice(5, 6);
						}
						if (tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex === 6) {
							return vWeeklyData.map(row => row.Date).slice(6, 7);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 0) {
							return vWeeklyData.map(row => row.Date).slice(7, 8);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 1) {
							return vWeeklyData.map(row => row.Date).slice(8, 9);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 2) {
							return vWeeklyData.map(row => row.Date).slice(9, 10);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 3) {
							return vWeeklyData.map(row => row.Date).slice(10, 11);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 4) {
							return vWeeklyData.map(row => row.Date).slice(11, 12);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 5) {
							return vWeeklyData.map(row => row.Date).slice(12, 13);
						}
						if (tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === 6) {
							return vWeeklyData.map(row => row.Date).slice(13, 14);
						}
					}
					),
					title: function(tooltipItem, data) {
						return '';
					}
				}
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				display: true
			}
		}
	}

	useEffect(() => {
		FWeekly();
	}, []);

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<h3 className="card-title text-success font-weight-bold">
					WEEKLY NOP CHART
				</h3>
				<div className="card-tools">
					<ul className="nav nav-pills ml-auto">
						<li className="nav-item text-info font-weight-bold">
						</li>
					</ul>
				</div>
			</div>
			<div className="card-body">
				<div className="tab-content p-0">
					{vWeeklyLoading ?
							<div className="d-flex justify-content-center">
								<i className="fas fa-3x fa-sync-alt fa-spin"/>
							</div>
						:
							<div className="Chart-Container">
								<Bar
									data={vWeeklyBarData}
									plugins={[ChartDataLabels]}
									options={vWeeklyBarOption}
									height={null}
									width={null}
								/>
							</div>
					}
				</div>
			</div>
		</div>
	)
}

export default WeeklyNOPChart