import CaddyGroupAttendance  from "./CaddyGroupAttendance";
import LastWeekCaddyAttendance from "./LastWeekCaddyAttendanceChart";
import ThisWeekCaddyAttendance from "./ThisWeekCaddyAttendanceChart";
import CaddyMasterTable from "./CaddyMasterTable";
import BuggyMasterTable from "./BuggyMasterTable";
import './StyleGO.css'

const GolfOperations = () => {
	return (
		<div className="container-fluid">
			<div className="content p-3">
				<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
					<div className="col-12">
						<div className="row">
							<LastWeekCaddyAttendance/>
							<ThisWeekCaddyAttendance/>
						</div>
						<CaddyMasterTable/>
						<BuggyMasterTable/>
						<CaddyGroupAttendance/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GolfOperations;