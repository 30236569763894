import { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import format from 'date-fns/format';

const CaddyMasterTable = () => {
	const [ CaddyAttendance, SetCaddyAttendance ] = useState( [] );
	const [ loading, setLoading ] = useState( false );
	const CaddyAttendancePlan = CaddyAttendance.map( Value => Value.CaddyAttendancePlan ).slice( 7, 14 );
	const CaddyAttendanceCheckin = CaddyAttendance.map( Value => Value.CaddyCheckin ).slice( 7, 14 );
	const CaddyPairedAM = CaddyAttendance.map( Value => Value.CaddyPairedAM ).slice( 7, 14 );
	const CaddyPairedPM = CaddyAttendance.map( Value => Value.CaddyPairedPM ).slice( 7, 14 );
	const CaddyUnpairedAM = CaddyAttendance.map( Value => Value.CaddyUnpairedAM ).slice( 7, 14 );
	const CaddyUnpairedPM = CaddyAttendance.map( Value => Value.CaddyUnpairedPM ).slice( 7, 14 );
	const CaddyCheckOut = CaddyAttendance.map( Value => Value.CaddyCheckOut ).slice( 7, 14 );
	const CaddyStandBy = CaddyAttendance.map( Value => Value.CaddyStandBy ).slice( 7, 14 );
	const CaddyAttendanceAbsent = CaddyAttendance.map( row => row.CaddyAbsent ).slice( 7, 14 );
	const PlayerAM = CaddyAttendance.map( Value => Value.PlayerAM ).slice( 7, 14 );
	const PlayerPM = CaddyAttendance.map( Value => Value.PlayerPM ).slice( 7, 14 );

	const PairingRateAM = CaddyPairedAM.map( function( val, index ){
		const rate = ( Math.round( ( +val / +CaddyAttendanceCheckin[index] * 100 ) * 100 ) / 100 );
		return isNaN(rate) ? 0 : rate.toFixed(1);
	});

	const PairingRatePM = CaddyPairedPM.map( function( val, index ){
		const rate = ( Math.round( ( +val / +CaddyAttendanceCheckin[index] * 100 ) * 100 ) / 100 );
		return isNaN(rate) ? 0 : rate.toFixed(1);
	});

	const GetCaddyAttendance = async () => {
		setLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try {
			const vURL = vAPIURL + "/Golf/Operation/Caddy";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios 
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						let i = 0
						for( i ; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[i] );
						}
						if( vStatus === 1 ){
							SetCaddyAttendance( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}
		catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}
		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false )
	}

	useEffect( () => {
		GetCaddyAttendance()
	}, [] )

	function createData( DayType ) {
 		return { DayType };
	}

	const DayType = [
		createData( 'MON' )
		, createData( 'TUE' )
		, createData( 'WED' )
		, createData( 'THU' )
		, createData( 'FRI' )
		, createData( 'SAT' )
		, createData( 'SUN' )
	];
	
	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<h3 className="card-title text-success font-weight-bold">
					CADDY MASTER TABLE
				</h3>
				<div className="card-tools">
					<ul className="nav nav-pills ml-auto">
						<li className="nav-item text-info font-weight-bold">
							THIS WEEK
						</li>
					</ul>
				</div>
			</div>
			{
				loading ?
				<div className="d-flex justify-content-center">
					<i className="fas fa-3x fa-sync-alt fa-spin"/>
				</div>
				:
					<div className="position-relative">
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableHead>
									<TableCell
										sx={{
											width: 200
											, fontWeight: 'bold'
										}}
										align="left"
										variant="head"
									>
										STATUS
									</TableCell>
									{DayType.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
												, fontWeight: 'bold'
											}}
											align="left"
											variant="head"
										>
											{Value.DayType}
										</TableCell>
									) ) }
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										ATTENDANCE PLAN
									</TableCell>										
									{CaddyAttendancePlan.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value}
										</TableCell>
									))}
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										ABSENT
									</TableCell>
									{CaddyAttendanceAbsent.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value}
										</TableCell>
									) ) }
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										ATTENDANCE ACTUAL
									</TableCell>
									{CaddyAttendanceCheckin.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value}
										</TableCell>
									) ) }
								</TableHead>
								<TableHead 
									rowSpan={6}
								>
									<TableCell 
										sx={{ border:0 }}
									>
									</TableCell>
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										AVAILABLE
									</TableCell>
									{CaddyAttendanceCheckin.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value}
										</TableCell>
									) ) }
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										PAIRED AM
									</TableCell>
									{CaddyPairedAM.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value}
										</TableCell>
									) ) }
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										PAIRED PM
									</TableCell>
									{CaddyPairedPM.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value}
										</TableCell>
									) ) }
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										UNPAIRED AM
									</TableCell>
									{CaddyUnpairedAM.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value}
										</TableCell>
									) ) }
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										UNPAIRED PM
									</TableCell>
									{CaddyUnpairedPM.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value}
										</TableCell>
									) ) }
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										CHECK OUT
									</TableCell>
									{CaddyCheckOut.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value}
										</TableCell>
									) ) }
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										STAND BY
									</TableCell>
									{CaddyStandBy.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value}
										</TableCell>
									) ) }
								</TableHead>
								<TableHead 
									rowSpan={6}
								>
									<TableCell 
										sx={{
											border:0
										}}
									>
									</TableCell>
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										PLAYER VS CADDY AM
									</TableCell>
									{PairingRateAM.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value} %
										</TableCell>
									))}
								</TableHead>
								<TableHead>
									<TableCell
										sx={{
											width: 200
										}}
										align="left"
										variant="head"
									>
										PLAYER VS CADDY PM
									</TableCell>
									{PairingRatePM.map( ( Value ) => (
										<TableCell
											sx={{
												width: 100
											}}
											align="left"
											variant="head"
										>
											{Value} %
										</TableCell>
									) ) }
								</TableHead>
							</Table>
						</TableContainer>
					</div>
			}
		</div>
	);
}

export default CaddyMasterTable 