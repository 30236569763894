import React from "react";
import WeeklyNOPChart from "./WeeklyNOPChart";
import NOPChart from "./NOPChart";
import NOPTable from "./NOPTable";
import './StyleNOP.css'

const NumberOfPlayer = () => {
	return (
		<div className="container-fluid">
			<div className="content p-3">
				<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
					<div className="col-12">
						<div className="row">
							<NOPChart Period={"Daily"}/>
						</div>
						<WeeklyNOPChart/>
						<div className="row">
							<NOPChart Period={"Monthly"}/>
							<NOPChart Period={"Yearly"}/>
						</div>
						<NOPTable/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NumberOfPlayer