import React, { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import { startOfWeek, addDays } from "date-fns";
import { vGlobalDateNow } from "../../Global";

const WeeklyDate = () => {
	//date state
	const [range, setRange] = useState([
		{
			startDate: addDays( startOfWeek( new Date( vGlobalDateNow ) ), 1 ),
			endDate: new Date( vGlobalDateNow ),
			key: "selection",
		},
	]);

	const [open, setOpen] = useState(false);

	const refOne = useRef(null);

	useEffect(() => {
		// event listeners
		document.addEventListener("keydown", hideOnEscape, false); //if u wanna access let them true just useEffect
		document.addEventListener("click", hideOnclickOutside, false); //if u wanna access let them true just useEffect
	}, []);

	const hideOnEscape = (e) => {
		if (e.key === "Escape") {
			setOpen(false);
		}
	};

	const hideOnclickOutside = (e) => {
		if (refOne.current && !refOne.current.contains(e.target)) {
			setOpen(false);
		}
	};

	const formatDateRange = (start, end) => {
		const formattedStart = format(start, "dd MMM");
		const formattedEnd = format(end, "dd MMM");
		return `${formattedStart} - ${formattedEnd}`;
	};

	return (
		<div className="calendarWrap">
			<div
				className="inputBox"
				onClick={() => setOpen((open) => !open)}
				style={{
					color: "#31a5e8",
					border: "none",
					pointerEvent: "none",
				}}
			>
				<span className="inputText">
					{formatDateRange(range[0].startDate, range[0].endDate)}
				</span>
			</div>
			<div ref={refOne}>
				{open && (
					<DateRange
						onChange={(item) => setRange([item.selection])}
						editableDateInputs={true}
						moveRangeOnfirstSelection={false}
						ranges={range}
						months={1}
						direction="horizontal"
						className="calendarElement"
					/>
				)}
			</div>
		</div>
	);
};

export default WeeklyDate;