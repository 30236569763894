import React, { useEffect, useState } from "react"
import axios from "axios";
import toastr from "toastr";
import { vAPIURL } from "../../Global";
import "./SwitchButtonStyle.css";

const RuleConfig = () => {
	const [ RuleArrayTemp, setRuleArrayTemp ] = useState( [] )
	const [ RuleArrayList, setRuleArrayList ] = useState( [] )
	const [ RuleArrayListCode, setRuleArrayListCode ] = useState( [] )
	const [ Rule, setRule ] = useState( "" )
	const [ LoadingPage, setLoadingPage ] = useState( true )

	const FGenRule = async () => {
		let vStatus = 1
		let vMessage = []

		try{
			const vURL = vAPIURL + "/Golf/Config/GenRule";
			const vData = {};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setRuleArrayList( vResponse.data.Input.Rule.List )
							setRuleArrayListCode( vResponse.data.Input.Rule.ListCode )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "FGenUsername Error 1" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "FGenUsername Error 2" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
	}

	const FMenuRuleSearch = async () => {
		setLoadingPage( true )
		let vStatus = 1
		let vMessage = []

		try{
			const vURL = vAPIURL + "/Golf/Config/Menu/" + Rule;
			const vData = {
				Action: {
					Value: "Search"
				}
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							setRuleArrayTemp( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoadingPage( false )
	}

	const FRuleEdit = async ( MenuId, vBool, RuleId ) => {
		let vStatus = 1
		let vMessage = []
		let vDataTemp = {}

		if( vBool == true ){
			vDataTemp = {
				Action: {
					Value: "Save"
				},
				Input: {
					Level: {
						Value: 1024
					},
					Read: {
						Value: 1
					},
				}
			};
		}
		else{
			vDataTemp = {
				Action: {
					Value: "Save"
				},
				Input: {
					Level: {
						Value: 1024
					},
					Read: {
						Value: 0
					},
				}
			};
		}

		try{
			const vURL = vAPIURL + "/Golf/Config/Rule/" + RuleId +"/Menu/" + MenuId;
			const vData = vDataTemp;
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( let i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
	}

	useEffect(() => {
		FGenRule()
	}, [])

	useEffect(() => {
		if( Rule != "" ){
			FMenuRuleSearch()
		}
	}, [Rule])

	const SwitchButton = ( { vBool, MenuId, RuleId } ) => {
		function strToBool( vBool ) {
			if( vBool === 'true'){
				return true;
			}
			else if( vBool === 'false'){
				return false;
			}
			else {
			}
		}

		const [ isOn, setIsOn ] = useState( strToBool(vBool) )

		const toggleSwitch = () => {
			setIsOn( !isOn )
			FRuleEdit( MenuId, !isOn, RuleId )
		}

		return (
			<label className="switch">
				<input type="checkbox" checked={isOn} onChange={toggleSwitch}/>
				<span className="slider"></span>
			</label>
		)
	}
	return (
		<div>
			<div className="card">
				<div className="card-header">
					<ul className="nav nav-pills ml-auto">
						<li className="nav-item text-info font-weight-bold">
							Rule Dashboard User
						</li>
					</ul>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-3 col-md-1 p-0">
							<div className="form-group m-0">
								<label>
									Rule name
								</label>
							</div>
						</div>
						<div className="col-6 col-md-2 p-0">
							<select
								value={ Rule }
								onChange={ (e) => setRule( e.target.value ) }
							>
								{
									RuleArrayList.map(
										( Value, Index ) => (
											<option key={Index} value={Value}>
												{RuleArrayListCode[Index]}
											</option>
										)
									)
								}
							</select>
						</div>
					</div>
				</div>
				{
					LoadingPage ?
					<div className="d-flex justify-content-center">
					</div>
					:
					<table className="table table-striped table-valign-middle">
						<thead>
							<tr>
								<td className="text-center font-weight-bold">Dashboard View</td>
								<td className="text-center font-weight-bold">Description</td>
								<td className="text-center font-weight-bold">Status Rule</td>
							</tr>
						</thead>
						<tbody>
							{
								RuleArrayTemp.map( 
									( Value, Index ) => (
										<tr key={Value.Id}>
											<td className="font-weight-bold">
												{Value.Menu}
											</td>
											<td className="font-weight-bold">
												{Value.Description}
											</td>
											<td className="text-center font-weight-bold">
												<SwitchButton 
													vBool={Value.SecBool}
													MenuId={Value.Id}
													RuleId={Rule}
												/>
											</td>
										</tr>
									)
								)
							}
						</tbody>
					</table>
				}
			</div>
		</div>
	)
}

export default RuleConfig