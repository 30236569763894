import CategoryRevenue from "./CategoryRevenue";
import PlayerCount from "./PlayerCount";
import CompareFood from "./CompareFood";
import { vBoolDev } from "../../Global"
import CategoryRevenueDev from "./Dev/CategoryRevenueDev";

const ExecutiveHighlight = () => {
	return (
		<div className="container-fluid">
			<div className="content p-3">
				<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
					<div className="col-12">
						{
							vBoolDev == true
							?
							<CategoryRevenueDev/>
							:
							<CategoryRevenue/>
						}
						<PlayerCount/>
						<CompareFood/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExecutiveHighlight