import RuleConfig from "./RuleConfig"
import UserConfig from "./UserConfig"

const SettingMenuUser = () => {
	return (
		<div className="container-fluid bg-white rounded">
			<div className="content p-3">
				<div className="row shadow-lg pt-2 mb-2 bg-white rounded">
					<div className="col-12">
						<UserConfig/>
						<RuleConfig/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SettingMenuUser