import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import MonthlyDate from './MonthlyDate';
import axios from 'axios';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import toastr from 'toastr';
import format from 'date-fns/format';

ChartJS.register(...registerables )

const ChartCCHMonthly = () => {
	const [ DataRoomMonthly, SetDataRoomMonthly ] = useState( [] );
	const [ loading, setLoading ] = useState( false );

	const GetTotalQtyMonthly = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/Reservation/RoomMonthly";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
					TypeRoom: {
						Value: "CCH"
					}
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;

						for(var i = 0; i < vResponse.data.Message.length; i++){
							vMessage.push(vResponse.data.Message[i]);
						}

						if(vStatus === 1){
							SetDataRoomMonthly(vResponse.data.Output.Result)
						}
					}
				)
				.catch(
					(vError) => {
						vStatus = 2;
						vMessage.push("Error");
					}
				);
		}catch(vError){
			vStatus = 2;
			vMessage.push("Error");
		}

		if(vMessage.length > 0){
			if(vStatus === 1){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading(false)
			
	}

	useEffect(() => {
		GetTotalQtyMonthly()
	}, [])

	const Color = [
		'rgb(255, 99, 132)',
		'rgb(3, 252, 227)',
		'rgb(3, 252, 15)',
		'rgb(252, 252, 3)',
		'rgb(252, 136, 3)',
		'rgb(171, 196, 255)',
		'rgb(255, 238, 147)',
		'rgb(128, 155, 206)'
	]

	let data = {
		labels: DataRoomMonthly.map( Value => Value.RoomCode ),
		datasets: [
			{
				label: 'Average' 
				, data: DataRoomMonthly.map( Value => Value.Average )
				, backgroundColor: 'rgba( 72, 212, 219, 0.9 )'
				, borderColor: 'rgba( 72, 212, 219, 0.9 )'
				, type: 'line'
				, datalabels: {
					labels: {
					  title: null
					}
				}
			}
			, {
				label: 'Qty' 
				, data: DataRoomMonthly.map( Value => Value.Qty )
				, backgroundColor: Color
				, borderColor: Color
				, borderWidth: 1 
			}
		]
	};

	let LabelTooltip = DataRoomMonthly.map( Value => Value.RoomCode )

	let option = {
		maintainAspecRatio: true,
		responsive : true,
		scales: {
			x: {
				beginAtZero: true
			},
			y: {
				beginAtZero: true
			}
		},
		plugins: {
			legend: {
				display: false,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 5,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			tooltip: {
				callbacks: {
					title: ( ( tooltipItem, data ) => {
						return '';
					}),
					label: ( ( tooltipItem, data ) => {
						if( tooltipItem.datasetIndex === 0 && tooltipItem.dataIndex >= 0 ){
							return "Average :" + tooltipItem.formattedValue
						}
						if( tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex >= 0 ){
							return LabelTooltip[tooltipItem.dataIndex]
						}
					})
				}
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				display: 'auto',
				font: {
					size: 15,
				}
			}
		},        
	}

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info font-weight-bold">
						CCH USAGE CHART MONTH TO DATE
					</li>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info">
						(In Qty)
					</li>
				</ul>
				<div>
					<MonthlyDate />
				</div>
			</div>
			<div className="card-body">
				<div className="tab-content p-0 ">
					{loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<>
							<div>
								<Bar
									data={data}
									plugins={[ChartDataLabels]}
									options={option}
									height={100}
								/>
							</div>
						</>
					}
				</div>
			</div>
		</div>
	)
}

export default ChartCCHMonthly